import { Modal } from 'antd'
import moment from 'moment'
import React from 'react'
import styles from "../styles/EventDetailModal.module.css"

function EventDetailModal({ setModalStates, modalStates }) {
    return (
        <>
            <Modal
                title={<div className={styles.modalDateTitle}>{moment(modalStates?.event?.eventDate).format('dddd, MMMM D YYYY')}</div>}
                visible={true}
                closable
                onCancel={() => setModalStates(prev => ({ ...prev, openDetailModal: false }))}
                footer={null}
                className={styles.EventDetailModal}
            >
                
                
                {((modalStates?.event?.type === "REMINDER" || modalStates?.event?.type === "SCHEDULE") && modalStates?.event?.start) ?
                    <div className={styles.modalContents}>
                        Start time: <span style={{ color: "green" }}>{moment(modalStates.event?.start).format('MM/DD/YYYY, h:mm A')}</span>
                    </div> : null}
                {((modalStates?.event?.type === "SCHEDULE") && modalStates?.event?.end) ?
                    <div className={styles.modalContents}>
                        End time: <span style={{ color: "green" }}>{moment(modalStates.event?.end).format('MM/DD/YYYY, h:mm A')}</span>
                    </div> : null}  
                {modalStates?.event?.dateOrDeadline ?
                    <div className={styles.modalContents}>
                        Due Date: <span style={{color: "red"}}>{moment(modalStates.event?.dateOrDeadline).format('MM/DD/YYYY')}</span> 
                    </div> : null}
                {modalStates?.event?.completedDateMili ?
                    <div className={styles.modalContents}>
                        Completed Date: <span style={{color: "green"}}>{moment(modalStates.event?.completedDateMili).format('MM/DD/YYYY')}</span>
                    </div> : null}
                <div className={styles.mainDetailContainer}>
                    {modalStates?.event?.address?.fullAddress ? <div className={styles.fullAddress}>{modalStates?.event?.address?.fullAddress}</div> : null}
                    {modalStates?.event?.eventName ? <div className={styles.eventName}>{modalStates?.event?.eventName}</div> :
                     modalStates?.event?.title ? <div className={styles.eventName}>{modalStates?.event?.title}</div> : null}
                    {modalStates?.event?.description ? <div className={styles.description}>{modalStates?.event?.description}</div> : null}

                </div>
            </Modal>
        </>
    )
}

export default EventDetailModal