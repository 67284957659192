import { officeTypeConstants } from "./action-types";

const initialState = {
  officeLoading: true,
  officeError: false,
  officeData: [],
  totalRecords:null,

  personsLoading: true,
  personsError: false,
  personsData: [],

  totalfirmAccessRecords:null,
  firmUpdateLoading: true,
  firmUpdateError: false,
  firmUpdateData: [],
  totalUpdateRecords:null,

  isAdminRoleUpdated: [],
};

const officeType = (state = initialState, action) => {
  switch (action.type) {
    case officeTypeConstants.OFFICE_TYPE_DATA:
      const data = [];
      action.officeData.forEach(element => {
        const franchise = franchiseData(element);

        const brokerage = brokerageData(element);

        // const office = officeData(element);

        const personData = {
          ...(element?.createdBy && { fullName: element?.createdBy?.fullName }),
          ...(element?.createdBy && { email: element?.createdBy?.email }),
        };
        data.push({
          name: element.type,
          franchise: franchise,
          brokerage: brokerage,
          admins: element.admins,
          // office: office,
          codes: element.codes,
          creator: personData,
          id: element._id,
          updateInProgress: element.updatedData ? true : false,
          licenseNumber: element.licenseNumber,
        });
      });
      return {
        ...state,
        officeLoading: action.officeLoading,
        officeError: action.officeError,
        officeData: data,
        totalRecords:action.totalRecords
      };
    case officeTypeConstants.OFFICE_TYPE_LOADING:
      return {
        ...state,
        officeLoading: action.officeLoading,
        officeError: action.officeError,
      };
    case officeTypeConstants.OFFICE_TYPE_ERROR:
      return {
        ...state,
        officeLoading: action.officeLoading,
        officeError: action.officeError,
      };
    case officeTypeConstants.PERSON_DATA:
      const dataInfo = [];
      // action.personsData.forEach((element) => {
      //   let franchise = {
      //     name: null,
      //     address: null,
      //   };

      //   if (element?.officeData?.officeId?.franchiseId) {
      //     franchise = franchiseIdReturn(element);
      //   }

      //   let brokerage = {
      //     name: null,
      //     address: null,
      //   };

      //   if (element?.brokerageData) {
      //     brokerage = brokerageIdReturn(element);
      //   }

      //   let office = {
      //     name: null,
      //     address: null,
      //   };

      //   if (element?.officeData?.officeId?.officeType === "FRANCHISE") {
      //     franchise = franchiseIfOffice(element);
      //   } else if (element?.officeData?.officeId?.officeType === "BROKERAGE") {
      //     brokerage = brokerageIfOffice(element);
      //   } else if (element?.officeData?.officeId?.officeType === "OFFICE") {
      //     office = officeId(element);
      //   }

      //   dataInfo.push({
      //     accessedBy: {
      //       fullName: element.fullName,
      //       email: element.email,
      //     },
      //     // status: element.officeData.isVerified,
      //     franchise: franchise,
      //     brokerage: brokerage,
      //     office: office,
      //     update: element.officeData.isVerified,
      //     id: element._id,
      //   });
      // });

      action.personsData.forEach((element) => {

        dataInfo.push({
          accessedBy: {
            fullName: element?.fullName,
            email: element?.email,
          },
          // status: element.officeData.isVerified,
          // franchise: franchise,
          brokerage: element?.brokerageData,
          // office: office,
          update: element?.isVerified,
          id: element?._id,
        });
      });
      return {
        ...state,
        personsLoading: false,
        personsError: false,
        personsData: dataInfo,
        totalfirmAccessRecords:action.totalRecords
      };
    case officeTypeConstants.PERSON_ERROR:
      return {
        ...state,
        personsLoading: false,
        personsError: true,
      };
    case officeTypeConstants.PERSON_LOADING:
      return {
        ...state,
        personsLoading: action.personsLoading,
        personsError: action.personsError,
      };
    case officeTypeConstants.FIRM_UPDATE_LOADING:
      return {
        ...state,
        firmUpdateLoading: true,
        firmUpdateError: false,
        firmUpdateData: []
      }
    case officeTypeConstants.FIRM_UPDATE_ERROR:
      return {
        ...state,
        firmUpdateLoading: false,
        firmUpdateError: true
      }
    case officeTypeConstants.FIRM_UPDATE_DATA:
      const dataUpdate = [];
      action.firmUpdateData.forEach((element) => {
        let franchiseUpdate = null, brokerageUpdate = null, officeUpdate = null;
        if(element.officeType === "FRANCHISE"){
          franchiseUpdate = updatedData(element);
        }
        else if(element.officeType === "BROKERAGE"){
          brokerageUpdate = updatedData(element);
        }
        else if(element.officeType === "OFFICE"){
          officeUpdate = updatedData(element);
        }
        const franchise = franchiseData(element);

        const brokerage = brokerageData(element);

        // const office = officeData(element);
        const personData = {
          ...(element?.createdBy && { fullName: element?.createdBy?.fullName }),
          ...(element?.createdBy && { email: element?.createdBy?.email }),
        };
        dataUpdate.push({
          name: element.officeType,
          franchise: franchise,
          brokerage: brokerage,
          status: element.updatedData.isVerified,
          // office: office,
          update: element.updatedData.isVerified,
          creator: personData,
          id: element._id,
          franchiseUpdate,
          brokerageUpdate,
          officeUpdate,
          licenseNumber:element?.licenseNumber,
        });
      });

      return {
        ...state,
        firmUpdateLoading: false,
        firmUpdateError: false,
        firmUpdateData: dataUpdate,
        totalUpdateRecords:action.totalRecords
      }
      // filter status
  case officeTypeConstants.FIRM_FILTER_STATUS_LOADING:
    return {
      ...state,
      personsLoading: true,
      personsError: null,
    };
  case officeTypeConstants.FIRM_FILTER_STATUS_SUCESS:
    const payload=[];
    action.personsData.forEach((element) => {
        let franchise = {
          name: null,
          address: null,
        };
        if (element?.officeData?.officeId?.franchiseId) {
          franchise = franchiseIdReturn(element);
        }

        let brokerage = {
          name: null,
          address: null,
        };
        if (element?.brokerageData) {
          brokerage = brokerageIdReturn(element);
        }

        let office = {
          name: null,
          address: null,
        };
        if (element?.officeData?.officeId?.officeType === "FRANCHISE") {
          franchise = franchiseIfOffice(element);
        } else if (element?.officeData?.officeId?.officeType === "BROKERAGE") {
          brokerage = brokerageIfOffice(element);
        } else if (element?.officeData?.officeId?.officeType === "OFFICE") {
          office = officeId(element);
        }

        payload.push({
          accessedBy: {
            fullName: element.fullName,
            email: element.email,
          },
          status: element.officeData.isVerified,
          franchise: franchise,
          brokerage: brokerage,
          office: office,
          update: element.officeData.isVerified,
          id: element._id,
        });
      });

      return {
        ...state,
        personsLoading: false,
        personsError: false,
        personsData: payload,
        totalfirmAccessRecords:action.totalRecords
      };
  case officeTypeConstants.FIRM_FILTER_STATUS_ERROR:
    return {
      ...state,
      personsData: [],
      personsLoading: action.personLoading,
      personsError: action.personError,
    };

  case officeTypeConstants.SET_ADMIN_ROLE_UPDATED:
    return {
      ...state,
      isAdminRoleUpdated: action.isAdminRoleUpdated,
    };


    default:
      return state;
  }
};

const franchiseIdReturn = (element) => {
  return {
    name: element.officeData.officeId.franchiseId.name,
    address:
      element.officeData.officeId?.franchiseId?.address?.streetNumber +
      " " +
      element.officeData.officeId?.franchiseId?.address?.streetName
      +
      (element.officeData.officeId?.franchiseId?.address?.unit
        ? `, ${element.officeData.officeId?.franchiseId?.address?.unit}, `
        : ", ") +
      element.officeData.officeId?.franchiseId?.address?.city +
      ", " +
      element.officeData.officeId?.franchiseId?.address?.state +
      " " +
      element.officeData.officeId?.franchiseId?.address?.zipCode,
  };
}

const brokerageIdReturn = (element) => {
  return {
    name: element?.brokerageData?.brokerageName,
    address: element?.brokerageData?.brokerageAddress,
  };
}

const franchiseIfOffice = (element) => {
  return {
    name: element.officeData.officeId.name,
    address:
      element.officeData.officeId?.address?.streetNumber +
      " " +
      element.officeData.officeId?.address?.streetName
      +
      (element.officeData.officeId?.address?.unit
        ? `, ${element.officeData.officeId?.address?.unit}, `
        : ", ") +
      element.officeData.officeId?.address?.city +
      ", " +
      element.officeData.officeId?.address?.state +
      " " +
      element.officeData.officeId?.address?.zipCode,
  };
}

const brokerageIfOffice = (element) => {
  return {
    name: element?.brokerageData?.brokerageName,
    address: element?.brokerageData?.brokerageAddress,
  };
}

const officeId = ( element ) => {
  return {
    name: element.officeData.officeId.name,
    address:
      element.officeData.officeId?.address?.streetNumber +
      " " +
      element.officeData.officeId?.address?.streetName
      +
      (element.officeData.officeId?.address?.unit
        ? `, ${element.officeData.officeId?.address?.unit}, `
        : ", ") +
      element.officeData.officeId?.address?.city +
      ", " +
      element.officeData.officeId?.address?.state +
      " " +
      element.officeData.officeId?.address?.zipCode,
  };
}

const franchiseData = (element) => {
  return {
    ...((element?.type === "FRANCHISE") ? {name: element.name} : (element?.franchiseId ? {name: element?.franchiseId?.name} : {name: null})),
     address: ((element?.type === "FRANCHISE") ? (element?.address?.streetNumber + " " + element?.address?.streetName + ", " +
     (element?.address?.unit ? `Unit ${element?.address?.unit}, ` : "") + element?.address?.city + ", " + element?.address?.state + " " +
     element?.address?.zipCode) :
    (element?.franchiseId ? (element?.franchiseId?.address?.streetNumber + " " + element?.franchiseId?.address?.streetName + ", " +
    (element?.franchiseId?.address?.unit ? ` ${element?.franchiseId?.address?.unit}, ` : " ") + element?.franchiseId?.address?.city + ", " +
     element?.franchiseId?.address?.state + " " + element?.franchiseId?.address?.zipCode): null)),
    streetNumber:((element?.type === "FRANCHISE") ? element?.address?.streetNumber:element?.franchiseId?.address?.streetNumber ),
    streetName:((element?.type === "FRANCHISE") ? element?.address?.streetName :element?.franchiseId?.address?.streetName),
    unit:((element?.type === "FRANCHISE") ?(element?.address?.unit ? element?.address?.unit : ""):(element?.franchiseId?.address?.unit? element?.franchiseId?.address?.unit : "")),
    city:((element?.type === "FRANCHISE")?element?.address?.city:element?.franchiseId?.address?.city ),
    state:((element?.type === "FRANCHISE")?element?.address?.state:element?.franchiseId?.address?.state),
    zipCode:((element?.type === "FRANCHISE")?element?.address?.zipCode:element?.franchiseId?.address?.zipCode),
    email: (element?.type === "FRANCHISE") ? element?.email : "",
    phonenumber: (element?.type === "FRANCHISE") ? element?.phoneNumber : "",
    officeLogo:((element?.type === "FRANCHISE")?element?.logo:element?.franchiseId?.logo),
  }
}

const brokerageData = (element) => {
  return {
    ...((element?.type === "BROKERAGE") ? {name: element.name} : (element?.brokerageId ? {name: element?.brokerageId?.name} : {name: null})),
    address: ((element?.type === "BROKERAGE") ? (element?.address?.streetNumber + " " + element?.address?.streetName + ", " +
     (element?.address?.unit ? `Unit ${element?.address?.unit}, ` : "") + element?.address?.city + ", " + element?.address?.state + " " +
     element?.address?.zipCode) :
    (element?.brokerageId ? (element?.brokerageId?.address?.streetNumber + " " + element?.brokerageId?.address?.streetName + ", " +
    (element?.brokerageId?.address?.unit ? ` ${element?.brokerageId?.address?.unit}, ` : " ") + element?.brokerageId?.address?.city + ", " +
     element?.brokerageId?.address?.state + " " + element?.brokerageId?.address?.zipCode): null)),
    streetNumber:((element?.type === "BROKERAGE") ?element?.address?.streetNumber:element?.brokerageId?.address?.streetNumber ),
    streetName:((element?.type === "BROKERAGE") ?element?.address?.streetName :element?.brokerageId?.address?.streetName),
    unit:((element?.type === "BROKERAGE") ?(element?.address?.unit?element?.address?.unit:""):(element?.brokerageId?.address?.unit?element?.brokerageId?.address?.unit:"")),
    city:((element?.type === "BROKERAGE")?element?.address?.city:element?.brokerageId?.address?.city ),
    state:((element?.type === "BROKERAGE")?element?.address?.state:element?.brokerageId?.address?.state),
    zipCode:((element?.type === "BROKERAGE")?element?.address?.zipCode:element?.brokerageId?.address?.zipCode),
    email: (element?.type === "BROKERAGE") ? element?.email : "",
    phonenumber: (element?.type === "BROKERAGE") ? element?.phoneNumber : "",
    officeLogo:((element?.type === "BROKERAGE")?element?.logo:element?.brokerageId?.logo),
  }
}

// const officeData = (element) => {
//   return {
//     ...((element?.officeType === "OFFICE") ? {name: element.name} : {name: null}),
//     address: ((element?.officeType === "OFFICE") ? (element?.address?.streetNumber + " " + element?.address?.streetName+ ", " +(element?.address?.unit ? `Unit ${element?.address?.unit}, ` : "") + element?.address?.city + ", " + element?.address?.state + " " + element?.address?.zipCode) : null),
//      streetNumber:((element?.officeType === "OFFICE") ?element?.address?.streetNumber:element?.brokerageId?.address?.streetNumber ),
//     streetName:((element?.officeType === "OFFICE") ?element?.address?.streetName :element?.brokerageId?.address?.streetName),
//     unit:((element?.officeType === "OFFICE") ?(element?.address?.unit?element?.address?.unit:" "):(element?.brokerageId?.address?.unit?element?.brokerageId?.address?.unit:" ")),
//     city:((element?.officeType === "OFFICE")?element?.address?.city:element?.brokerageId?.address?.city ),
//     state:((element?.officeType ==="OFFICE")?element?.address?.state:element?.brokerageId?.address?.state),
//     zipCode:((element?.officeType === "OFFICE")?element?.address?.zipCode:element?.brokerageIdfranchiseId?.address?.zipCode),
//     email: (element?.officeType === "OFFICE") ? element?.email : "",
//     phonenumber: (element?.officeType === "OFFICE") ? element?.phoneNumber : "",
//     officeLogo:((element?.officeType === "FRANCHISE")?element?.officeLogo:element?.franchiseId?.officeLogo),
//   }
// }

const updatedData = ( element ) => {
  return {
    name: element.updatedData.name,
    address:
      element.updatedData?.address?.streetNumber +
      " " +
      element.updatedData?.address?.streetName +
      " " +
      (element.updatedData?.address?.unit
        ? ` ${element.updatedData?.address?.unit}, `
        : "") +
      element.updatedData?.address?.city +
      ", " +
      element.updatedData?.address?.state +
      " " +
      element.updatedData?.address?.zipCode,
      email: element.updatedData?.email,
       phoneNumber: element?.updatedData?.phoneNumber,
       officeLogo: element?.updatedData?.logo,
  };
}



export { officeType };
