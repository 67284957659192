import React from "react";
import { formatPhoneNumber } from "../../Common/utils/universalFuntions";
import newSt from "../style/RtdWindow.module.css";
import { Avatar, Modal, Typography } from "antd";
import { UserOutlined, MailOutlined } from "@ant-design/icons";
import { BsTelephone } from "react-icons/bs";
import { BiUser } from "react-icons/bi";
const { Text } = Typography;

const AgentDetailsModal = ({ userState, setUserState, isSmallScreen, isMediumScreen }) => {
  const { agentData, source, showDetailModal } = userState || {};
  const isSellerAgent = source === "SELLER_AGENT";
  const agentName = agentData?.fullName || agentData?.[isSellerAgent ? "SellerBrokerName" : "BuyerBrokerName"];
  const agentEmail = agentData?.email || agentData?.[isSellerAgent ? "SellerBrokerEmailAddress" : "BuyerBrokerEmailAddress"];
  const agentPhone = agentData?.mobilePhoneNumber?.phoneNumber || agentData?.[isSellerAgent ? "SellerBrokerPhoneNumber" : "BuyerBrokerPhoneNumber"];

  const handleDetailOk = () => {
    setUserState({
      showDetailModal: false,
      agentData: {},
      source: "",
    });
  };
  const handleDetailCancel = () => {
    setUserState({
      showDetailModal: false,
      agentData: {},
      source: "",
    });
  };
  return (
    <>
      <Modal title={<div style={{ textAlign: "center", fontSize: isSmallScreen ? "14px" : "18px", fontWeight: "bold", color: "#178DFA" }}>{source === "SELLER_AGENT" ? "SELLER AGENT DETAILS" : "BUYER AGENT DETAILS"}</div>} footer={false} visible={showDetailModal} onOk={handleDetailOk} onCancel={handleDetailCancel}>
        <div className={newSt.agentDetailsModalContent}>
          <div>
            {agentData?.profileImg?.original ? (
              <Avatar src={agentData?.profileImg?.original} style={{ cursor: "pointer", margin: "auto" }} size={isSmallScreen || isMediumScreen ? 32 : 64} icon={<UserOutlined style={{ fontSize: isSmallScreen ? "25px" : isMediumScreen ? "45px" : "54px" }} />} />
            ) : (
              <Avatar size={isSmallScreen || isMediumScreen ? 32 : 64} style={{ cursor: "pointer", margin: "auto", fontSize: "27px", border: "1px solid #085191", background: "#085191" }}>
                {agentName ? (
                  agentName
                    ?.split(" ")
                    .map((namePart) => namePart.charAt(0).toUpperCase())
                    .join("")
                    .slice(0, 2)
                ) : (
                  <UserOutlined style={{ fontSize: isSmallScreen ? "25px" : isMediumScreen ? "45px" : "54px" }} />
                )}
              </Avatar>
            )}
          </div>

          <div className={newSt.userDetails}>
            <div className={newSt.userDetailsFlex}>
              {agentName ? (
                <>
                  <BiUser style={{ fontSize: isSmallScreen ? "20px" : isMediumScreen ? "25px" : "30px" }} />
                  <Text style={{ fontWeight: "bold", color: "grey", fontSize: isSmallScreen ? "12px" : isMediumScreen ? "16px" : "18px" }}>{agentName}</Text>
                </>
              ) : (
                ""
              )}
            </div>
            <div className={newSt.userDetailsFlex}>
              {agentEmail ? (
                <>
                  <MailOutlined style={{ fontSize: isSmallScreen ? "20px" : isMediumScreen ? "25px" : "30px" }} />
                  <Text style={{ fontWeight: "bold", color: "grey", fontSize: isSmallScreen ? "12px" : isMediumScreen ? "16px" : "18px" }} ellipsis copyable>
                    {agentEmail}
                  </Text>
                </>
              ) : (
                ""
              )}
            </div>
            <div className={newSt.userDetailsFlex}>
              {agentPhone ? (
                <>
                  <BsTelephone style={{ fontSize: isSmallScreen ? "20px" : isMediumScreen ? "25px" : "30px" }} />
                  <Text style={{ fontWeight: "bold", color: "grey", fontSize: isSmallScreen ? "12px" : isMediumScreen ? "16px" : "18px" }} ellipsis copyable>
                    {formatPhoneNumber(agentPhone)}
                  </Text>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AgentDetailsModal;
