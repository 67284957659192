const buildSignatureBlocks = (signers, type, rootDocData, requiredOpenFor) => {
  let signerType;
  if (type === "Buyers") {
    signerType = "Buyer";
  } else if (type === "Sellers") {
    signerType = "Seller";
    if (rootDocData?.contractType === "ERTL") signerType = "Landlord";
    if (rootDocData?.contractType === "ETCT") signerType = "Tenant";
  } else {
    signerType = type;
  }
  if (Array.isArray(signers)) {
    const isDWRAndSeller = rootDocData?.contractType === "DWR" && signerType === "Seller";
    const signerTitleCriteria = (signerType === 'Authorized' && rootDocData?.contractType === 'CI');
    let html = "";
    signers?.forEach((element, i) => {
    
      if (element.isCorp) {
        element.signers.forEach(signer => {
          html += `
          <div class="signature-block">
            <table class="mt">
              <thead></thead>
              <tbody>  
                <tr>
                  <td style="width: 75%;" class="border-bottom-input" id="${requiredOpenFor + "_" + signer.personId +"_key_"+ element.key}">
                    ${'<div class="signature-block ' + ((signer.personId === rootDocData?.builtForId) && (element.key === rootDocData?.key) && (rootDocData?.openFor === requiredOpenFor) && rootDocData?.signable && !rootDocData?.checkForDisable?.disabled  ? "active-signer clickable" : "inactive-signer") + '" data-signerId="' + signer.personId +'" data-signerKey="' + element.key +'" ></div>'}
                  </td>
                  <td style="width: 25%; vertical-align: bottom;" class="border-bottom-input ua tb "  data-signatureDate="${requiredOpenFor + "_" + signer.personId + "_key_"+ element.key}"></td>
                </tr>
                <tr>
                  <!--COMMENTED OUT THIS LINE <td>${isDWRAndSeller ? "Signature: " : `${signerType}'s Signature: `}<span class="ua tb" data-signerFullName="${requiredOpenFor + "_" + signer.personId +"_key_" +element.key}">${signer.fullName || ""}</span><br>Authorized Signer,  ${element.fullName || ""} </td>-->
                  <td>${isDWRAndSeller ? "Signature: " : `${signerType}'s Signature: `}<span class="ua tb">${element.fullName || ""}</span><br><span class="ua tb" data-signerFullName="${requiredOpenFor + "_" + signer.personId +"_key_" +element.key}">${signer.fullName || ""}</span>${signer[`title`] ? `, <span class="ua tb">${signer[`title`]}</span>` : ""}</td>
                  <td>Date </td>
                </tr>
              </tbody>
            </table>
          </div>`;
        });
      } else {
        html += `
        <div class="signature-block">
            <table class="mt">
                <thead></thead>
                <tbody>
                    <tr>
                        <td style="${signerTitleCriteria ? 'width: 55%;' : 'width: 80%;'}" class="border-bottom-input" id="${requiredOpenFor + "_" + (element.personId ? element.personId : (element._id || requiredOpenFor)) + "_key_" + (element.key)}">
                        <div class="signature-block ${(((((element?.personId || element._id) === rootDocData?.builtForId) && (element.key === rootDocData?.key) && (rootDocData?.openFor === requiredOpenFor) && !rootDocData?.checkForDisable?.disabled) ||
                          ((rootDocData?.openFor === requiredOpenFor) && ("TITLE_COMPANY" === requiredOpenFor) && !rootDocData?.checkForDisable?.disabled)) && rootDocData?.signable) ? "active-signer clickable" : "inactive-signer"}" data-signerId="${element.personId || element._id}"  data-signerKey="${element.key}">
                       </div>
                        </td>
                        ${(signerTitleCriteria ) ? '<td style="width: 25%;  vertical-align: bottom;" class="border-bottom-input"><input  id="SignerTitleField" type="text" name="SignerTitleInput" style="border-bottom: none !important;"/></td>' : ''}
                        <td style="width: 25%; vertical-align: bottom;" class="border-bottom-input ua tb " data-signatureDate="${requiredOpenFor + "_" + (element.personId ? element.personId : (element._id || requiredOpenFor)) + "_key_"+ element.key}"></td>
                    </tr>
                    <tr>
                        <td>${isDWRAndSeller ? "Signature: " : `${signerType}${signerType === 'Authorized' ? " Signer: " : `'s Signature: `} `}<span class="ua tb" data-signerFullName="${requiredOpenFor + "_" + (element.personId ? element.personId : (element._id || element.email))}">${element.fullName || ""}</span></td>
                        ${signerTitleCriteria ?'<td >Title </td>': ""}
                        <td>Date </td>
                    </tr>
                </tbody>
            </table>
        </div>`;
      }
    });
    return html;
  } else {
    console.log("unable to build signature block for", signers);
    return "";
  }
};

export default buildSignatureBlocks;
