import React from "react";
import { Alert, Avatar, Button, List, Modal } from "antd";
import { EmailDetails } from "./EmailDetails";
import { transactionLaneDataHandle } from "../../Rtd/state/actions";
import { useDispatch } from "react-redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { generateInitialsFromObj } from "../utils/universalFuntions";

const AddEmail = ({ enterEmailModal, setEnterEmailModal, form, handleAddEmail, clickedDataEmail, role, selectedData, userAuthKey, source, offerId }) => {
  const dispatch = useDispatch()

  const handleAddEmailToCRM = ({ values, check }) => {
    let urlData = {
      addToProperty: false,
      offerId: (source === "CONTACT_SELLING_RTD" || source === "CONTACT_BUYING_RTD") ? offerId : selectedData?.offerId,
      sellerDetail: {
        fullName: clickedDataEmail?.personId?.fullName ? clickedDataEmail?.personId?.fullName : clickedDataEmail?.fullName,
        key: clickedDataEmail?.key || clickedDataEmail?._id,
        isCorp: clickedDataEmail?.isCorp,
        ...(values?.email?.length > 0 ? { email: values?.email?.toLowerCase() } : {}),
      },
      ...(values?._id ? { selectedId: values?._id } : {}),
      ...(check ? { newClient: true } : {})
    };

    Modal.confirm({
      title: `Are you sure you want to ${!check ? "select this contact?" : "create new contact?"}`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(transactionLaneDataHandle.addEmailFun({ data: urlData, userAuthKey, source }));
        // setNestedModalOpen({ ...nestedModalOpen, isEmailModalVisible: false })
        setEnterEmailModal({ ...enterEmailModal, visible: false, data: {} });
        form?.resetFields();
      },
      onCancel() { },
    });
  };

  const handleCancelModal = () => {
    setEnterEmailModal({ ...enterEmailModal, visible: false, data: {} })
    form?.resetFields();
  }

  return (
    <>
      {/* Modal for adding the email  */}
      <Modal visible={enterEmailModal?.visible} footer={false}
        title={
          <div>
            <p style={{ textAlign: "center", color: "#178DFA", fontSize: "22px", fontWeight: "bold" }}>
              {enterEmailModal?.data?.showClients ? "Duplicate Contacts Found" : "Add Email"}
            </p>
            {enterEmailModal?.data?.showClients && <Alert
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                width: "100%",
                margin: "auto"
              }}
              message="Please select if current contact exists from the list below or create a new contact."
              type="info"
              showIcon
            />}
          </div>}
        onCancel={handleCancelModal}>
        {/* <EmailDetails form={form} onFinish={handleAddEmail} /> */}

        {enterEmailModal?.data?.showClients ? (
          <div
            style={{ height: "400px", overflow: "hidden", overflowY: "auto" }}
          >
            <List
              itemLayout="horizontal"
              dataSource={enterEmailModal?.data?.allClients}
              renderItem={(item) => (
                <List.Item
                  onClick={() => handleAddEmailToCRM({ values: { email: form?.getFieldValue("email"), _id: item?._id }, check: false })}
                  style={{ padding: 5, borderRadius: "5px", cursor: "pointer" }}
                  hoverable
                  onMouseEnter={(e) => {
                    e.currentTarget.style.background = "#a8c5dc";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.background = "white";
                  }}
                >
                  <List.Item.Meta
                    avatar={
                      item?.profileImg?.original ? (
                        <Avatar
                          style={{ background: "#085191" }}
                          src={item?.profileImg?.original}
                        />
                      ) : (
                        <Avatar style={{ background: "#085191" }}>
                          {generateInitialsFromObj(item)}
                        </Avatar>
                      )
                    }
                    title={item?.fullName}
                  />
                </List.Item>
              )}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "25%",
                margin: "10px auto auto auto",
              }}
            >
              <span style={{ border: "2px solid grey", flex: 1 }}></span>
              <span style={{ color: "grey", margin: "0 10px" }}>OR</span>
              <span style={{ border: "2px solid grey", flex: 1 }}></span>
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
              <Button onClick={() => handleAddEmailToCRM({ values: { email: form?.getFieldValue("email") }, check: true })} style={{ textAlign: "center" }} type="primary">
                Create New
              </Button>
            </div>
          </div>
        ) : (
          <EmailDetails onFinish={handleAddEmail} setEnterEmailModal={setEnterEmailModal} form={form} />
        )}

      </Modal>
    </>
  );
};

export default AddEmail;
