import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RtdRow from "./RtdRow";
import { Avatar, Modal, Spin, Tooltip, Typography, message, Image } from "antd";
import { CopyOutlined, SnippetsOutlined, UserOutlined,  } from "@ant-design/icons";
import { FolderFilled, FolderOpenOutlined, FileImageOutlined, FileTextOutlined } from "@ant-design/icons";
import { FaRegFilePdf } from "react-icons/fa";
import { transactionLaneDataHandle } from "../state/actions";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import newSt from "../style/RtdWindow.module.css";
import '../style/rtdWindow.css'
import housePlaceHolder from "../../Assets/Icons/imagePlaceholder.webp";
import AgentDetailsModal from "./AgentDetailsModal";
const { Text } = Typography;

function RtdWindow({ tags, selectedData, client, userAuthKey, URLofferTableJSON }) {
  const dispatch = useDispatch();
  const { getTransactionLaneData, getSharedDocsData, getSharedDocsLoading } = useSelector((state) => state.rtdTransactionLane);



  const [copyid, setCopyid] = useState("Copy Id");
  const [copyicon, setcopyicon] = useState(true);
  const [showSharedDocsModal, setShowSharedModal] = useState(false);
  const isSmallScreen = useMediaQuery({maxWidth: 550});
  const isMediumScreen = useMediaQuery({minWidth: 551, maxWidth: 850});

  function codecopied() {
    let code = document.getElementById("code_to_copy").innerHTML;
    setCopyid("Copied");

    setcopyicon(false);
    navigator.clipboard.writeText(code).then(
      function () {
        message.success("Transaction id has been copied!");
      },
      function () {
        message.error("Sorry,Failed to copied transaction id.");
      }
    );

    setTimeout(() => {
      setCopyid("Copy Id");
      setcopyicon(true);
    }, 5000);
  }

  const handleSharedFolderClick = () => {
    if (selectedData?.offerId && userAuthKey) {
      dispatch(transactionLaneDataHandle.getSharedDocsFun({ offerId: selectedData?.offerId, userAuthKey }));
    }
    setShowSharedModal(true);
  };



  const [showFileModal, setShowFileModal] = useState(false);
  const [selectedDocUrl, setSelectedDocUrl] = useState('');

  const handleDocumentClick = (ele) => {
    setSelectedDocUrl(ele);
    setShowFileModal(true);
  };

  const handleModalClose = () => {
    setSelectedDocUrl('');
    setShowFileModal(false);
  };

  return (
    <>
      <Modal visible={showSharedDocsModal} onOk={() => {}} footer={false} width={800} onCancel={() => setShowSharedModal(false)}>
        <div className={newSt.sharedDocModalContents}>
          <div className={newSt.sharedDocHeading}>
            <div style={{ color: "#2976be" }}>
              <FolderOpenOutlined />
            </div>
            Shared
          </div>
          <hr />
          <div className={newSt.tableOuterScroll}>
            <div className={newSt.tableOuterContainer}>
              {getSharedDocsLoading ? (
                <div className={newSt.spinner} >
                  <Spin size="large" />
                </div>
              ) : (
                <>
                  {getSharedDocsData?.length > 0 ? (
                    <table className={newSt.sharedFolderTable}>
                      <thead>
                        <tr>
                          <th className={newSt.FileName}> File Name</th>
                          <th>Shared Date</th>
                          <th> Shared By</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getSharedDocsData?.map((ele) => (
                          <tr key={ele._id}>
                            <td className={newSt.fileNameColumn} onClick={() => (ele?.fileUrl ? handleDocumentClick(ele) : null)}>
                              <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                                {ele.fileType === "IMAGE" ? <FileImageOutlined style={{ color: "#ecd540"}} /> : ele.fileType === "PDF" ? <FaRegFilePdf style={{ color: "red" }} /> : <FileTextOutlined style={{ color: "#74b3ed" }} />}
                                {ele.offerDocument ? ele.documentName : ele?.fileName || ""}
                              </div>
                            </td>
                            <td className={newSt.sharedDate}>
                              {moment(ele.sentDateMili).format("MM/DD/YYYY hh:mm A")}
                            </td>
                            <td className={newSt.sharedByName}>
                              {ele?.sharedBy?.fullName || ""}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : null}

                  {/* Modal for displaying selected document */}
                  <Modal
                    visible={showFileModal}
                    onCancel={handleModalClose}
                    footer={null}
                    width={selectedDocUrl.fileType === 'PDF' ? 800 : 520}
                    className="showImagePdfModal"
                    style={{top: '10vh'}}
                  >
                    {selectedDocUrl && (
                      <>
                        {selectedDocUrl.fileType === 'PDF' ? (
                          <div style={{padding: '20px', width: '100%'}}>
                            <iframe title="fileURl" className="iframePreRtd" src={selectedDocUrl.fileUrl} style={{ width: '100%', height: '80vh'}} />
                          </div>
                        ) : (
                          <div style={{padding: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh'}}>
                            <Image src={selectedDocUrl.fileUrl} alt="Document" style={{ maxWidth: '100%', maxHeight: '80vh' }} />
                          </div>
                        )}
                      </>
                    )}
                  </Modal>
                </>
              )}{" "}
            </div>
          </div>
        </div>
      </Modal>
      <div className={newSt.preRTDLayoutOuterContainer}>
          {URLofferTableJSON?.fullAddress ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className={newSt.propertyDetails}>
                  <Image
                    preview
                    style={{width: isSmallScreen ? '30px' : isMediumScreen ? '50px' : '60px'}}
                    src={
                      typeof URLofferTableJSON?.propertyImage == "string" && URLofferTableJSON?.propertyImage !== ""
                        ? URLofferTableJSON?.propertyImage
                        : housePlaceHolder
                    }
                    alt="home"
                  />{" "}
                <div className={newSt.addressDiv}>
                  {URLofferTableJSON?.fullAddress}
                  {/* {URLofferTableJSON?.listingId ? (
                  <p>
                    MLS Listing Id: <>{URLofferTableJSON?.listingId}</>
                  </p>
                ) : (
                  <p></p>
                )} */}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {selectedData?.transactionId ? (
            <div className={newSt.trnxnDiv}>
              {" "}
              <div>
                <Tooltip title={copyid} placement="right">
                  <button
                    onClick={codecopied}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      borderRadius: "8px",
                      alignItems: "center",
                      border: "none",
                      fontSize: isSmallScreen ? "12px" : isMediumScreen ? "14px" : "16px",
                    }}
                  >
                    <text id="code_to_copy" style={{ fontWeight: "bold" }}>
                      #Txn_Id:{selectedData?.transactionId}
                    </text>
                    {copyicon ? (
                      <CopyOutlined
                        type="copy"
                        style={{
                          fontWeight: "bold",
                          marginLeft: "5px",
                        }}
                      />
                    ) : (
                      <SnippetsOutlined
                        style={{
                          color: "hsl(209deg 72% 51%)",
                          fontWeight: "bold",
                          marginLeft: "5px",
                        }}
                      />
                    )}
                    <p style={{ paddingTop: "13px", paddingLeft: "10px" }}></p>
                  </button>
                </Tooltip>
              </div>
            </div>
          ) : (
            ""
          )}
        {/* buyer seller divs  */}
        <BuyerSellerDiv selectedData={selectedData} isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen} />
        <div className={newSt.preRTDDocOuterContainer}>
          <div className={newSt.documentContainer}>
            {getTransactionLaneData &&
              getTransactionLaneData.map((item, i) => {
                return <RtdRow {...item} key={item._id} tags={tags} RTDInitiater={tags} client={client} selectedData={selectedData} userAuthKey={userAuthKey} />;
              })}
          </div>
          {!showSharedDocsModal && (selectedData?.status === "ACCEPTED" || "SUBMITTED" || tags === "SELLER") ? (
            <button className={newSt.sharedFolderBtn} onClick={handleSharedFolderClick}>
              <div style={{ color: "#2976be"}}>
                <FolderFilled />
              </div>
              Shared
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

function BuyerSellerDiv({ selectedData, isSmallScreen, isMediumScreen }) {
  const [userState, setUserState] = useState({
    showDetailModal: false,
    agentData: {},
    source: "",
  });

  const handleShowAgentDetail = (item, source) => {
    if (source === "SELLER_AGENT") {
      setUserState({
        showDetailModal: true,
        agentData: item?.sellerAgentId || item?.transactionData,
        source,
      });
    } else {
      setUserState({
        showDetailModal: true,
        agentData: item?.buyerAgentId || item?.transactionData,
        source,
      });
    }
  };
  const sellerAgentName = selectedData?.sellerAgentId?.fullName || selectedData?.transactionData?.["SellerBrokerName"];
  const buyerAgentName = selectedData?.buyerAgentId?.fullName || selectedData?.transactionData?.["BuyerBrokerName"];
  // console.log("selectedData", selectedData);
  return (
    <>
      {/* Agent Details modal  */}
      {userState?.showDetailModal && <AgentDetailsModal userState={userState} setUserState={setUserState} isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen} />}

      <div className={newSt.buyerSellerBox}>
        <div className={newSt.sellerContainer}>
          {selectedData?.sellerAgentId?.profileImg?.original ? (
            <Avatar src={selectedData.sellerAgentId.profileImg.original} onClick={() => handleShowAgentDetail(selectedData, "SELLER_AGENT")} size={isSmallScreen || isMediumScreen ? 32 : 64} style={{ cursor: "pointer", margin: "auto" }} icon={<UserOutlined style={{ fontSize: "34px" }} />} />
          ) : (
            <Avatar onClick={() => handleShowAgentDetail(selectedData, "SELLER_AGENT")} size={isSmallScreen || isMediumScreen ? 32 : 64} style={{ cursor: "pointer", margin: "auto", fontSize: "27px", border: "1px solid #085191", background: "#085191" }}>
              {sellerAgentName ? (
                sellerAgentName
                  ?.split(" ")
                  .map((namePart) => namePart.charAt(0).toUpperCase())
                  .join("")
                  .slice(0, 2)
              ) : (
                <UserOutlined style={{ fontSize: "34px" }} />
              )}
            </Avatar>
          )}

          <div style={{ maxWidth: "130px" }}>
            <Text ellipsis style={{ fontWeight: "bold", color: "grey", fontSize: isSmallScreen ? "12px" : isMediumScreen ? "14px" : "18px" }}>
              {sellerAgentName}
            </Text>
          </div>
          <div className={newSt.sellerDesk}>Seller Agent</div>
        </div>

        <div className={newSt.buyerContainer}>
          {selectedData?.buyerAgentId?.profileImg?.original ? (
            <Avatar src={selectedData.buyerAgentId.profileImg.original} onClick={() => handleShowAgentDetail(selectedData, "BUYER_AGENT")} size={isSmallScreen || isMediumScreen ? 32 : 64} style={{ cursor: "pointer", margin: "auto" }} icon={<UserOutlined style={{ fontSize: "34px" }} />} />
          ) : (
            <Avatar onClick={() => handleShowAgentDetail(selectedData, "BUYER_AGENT")} size={isSmallScreen || isMediumScreen ? 32 : 64} style={{ cursor: "pointer", margin: "auto", fontSize: "27px", border: "1px solid #085191", background: "#085191" }}>
              {buyerAgentName ? (
                buyerAgentName
                  ?.split(" ")
                  .map((namePart) => namePart.charAt(0).toUpperCase())
                  .join("")
                  .slice(0, 2)
              ) : (
                <UserOutlined style={{ fontSize: "34px" }} />
              )}
            </Avatar>
          )}
          <div style={{ maxWidth: "130px" }}>
            <Text ellipsis style={{ fontWeight: "bold", color: "grey", fontSize: isSmallScreen ? "12px" : isMediumScreen ? "14px" : "18px" }}>
              {buyerAgentName}
            </Text>
          </div>
          <div className={newSt.buyerDesk}>Buyer Agent</div>
        </div>
      </div>
    </>
  );
}

export default RtdWindow;
