import React, { useEffect, useRef, useState } from 'react'
import { Button, Input, Select, Space, Table, Tooltip, Typography } from 'antd';
import "../styles/logs.css";
import { useDispatch, useSelector } from 'react-redux';
import { logsDataHandle } from '../state/actions';
import { Loading } from '../../Common';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import { SearchOutlined, FilterFilled, } from "@ant-design/icons"
import Highlighter from 'react-highlight-words';

function AgentCreatedLogs() {
  const dispatch = useDispatch();
  const searchRef = useRef(false);
  const searchInput = useRef();
  const [searchText, setsearchText] = useState("");
  const [searchedColumn, setsearchedColumn] = useState("");
  const [disableBtn, setdisableBtn] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState({ logType: 'AGENT_CREATED_LOGS' });
  const { getLogsData, getLogsLoading, getLogsDataError, totalRecords } = useSelector((state) => state.getLogsData);

  useEffect(() => {
    let queryData = {
      ...query,
      page: currentPage,
    }
    dispatch(logsDataHandle.getAgentCreatedLogsData(queryData));
    setQuery(queryData);
  }, [currentPage]);


  const handleResetFilter = () => {
    let queryData = {
      logType: 'AGENT_CREATED_LOGS',
      page: currentPage, // persist on the same page even after reset filter button clicked as we are removing the filters not changing the page.
    };
    dispatch(logsDataHandle.getAgentCreatedLogsData(queryData));
    setQuery(queryData);
    setsearchText("");
    searchRef.current = false;
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    searchRef.current = true;
    setsearchText(selectedKeys[0]);
    let queryData = {
      ...query,
      ...(
        dataIndex === 'createdAgentData' ? { userNameOrEmail: selectedKeys[0] } :
          dataIndex === 'createdByData' ? { createdByNameOrEmail: selectedKeys[0] } : {}
      ),
    };
    dispatch(logsDataHandle.getAgentCreatedLogsData(queryData));
    setQuery(queryData);
    setsearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, setSelectedKeys, dataIndex) => {
    let updatedQueryData = { ...query };

    // Remove the parameters dependent on the column being reset
    if (dataIndex === 'createdAgentData') {
      delete updatedQueryData.userNameOrEmail;
    } else if (dataIndex === 'createdByData') {
      delete updatedQueryData.createdByNameOrEmail;
    } else if (dataIndex === 'createdAt') {
      delete updatedQueryData.sortByCreatedAt;
    } else if (dataIndex === 'createdThrough') {
      delete updatedQueryData.createdThrough;
    } else if (dataIndex === 'platformUsed') {
      delete updatedQueryData.platformUsed;
    }

    // Update the query with the modified data
    dispatch(logsDataHandle.getAgentCreatedLogsData(updatedQueryData));
    setQuery(updatedQueryData);
    setsearchText("");
    searchRef.current = false;

    // Clear filters and selected keys
    clearFilters();
    setSelectedKeys("");
  };


  const handleSort = (dataIndex) => {
    let queryData = {
      ...query,
      ...(
        dataIndex === 'createdAt' ? { sortByCreatedAt: searchText } :
          dataIndex === 'createdThrough' ? { createdThrough: searchText } :
            dataIndex === 'platformUsed' ? { platformUsed: searchText } : {}
      )
    };
    dispatch(logsDataHandle.getAgentCreatedLogsData(queryData));
    setQuery(queryData);
  };

  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }}
          onPressEnter={() => { handleSearch(selectedKeys, confirm, dataIndex); setdisableBtn(false); }}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => { handleSearch(selectedKeys, confirm, dataIndex); setdisableBtn(false); }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => { handleReset(clearFilters, setSelectedKeys, dataIndex); setdisableBtn(true); }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  let getSort = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <div style={{ marginBottom: "10px" }}>
          <Select
            style={{ width: "100%" }}
            placeholder="Select Order"
            onChange={(role, event) => {
              setsearchText(role)
            }}
            options={
              dataIndex === 'createdAt' ?
                [
                  {
                    value: "ASC",
                    label: "ASCENDING",
                  },
                  {
                    value: "DESC",
                    label: "DESCENDING",
                  },
                ] :
                dataIndex === 'createdThrough' ?
                  [
                    {
                      value: "ADMIN",
                      label: "ADMIN",
                    },
                    {
                      value: "SIGN-UP",
                      label: "SIGN-UP",
                    },
                  ] :
                  dataIndex === "platformUsed" ?
                    [
                      {
                        value: "WEB",
                        label: "WEB",
                      },
                      {
                        value: "MOBILE",
                        label: "MOBILE",
                      },
                    ]
                    :
                    []
            }
          />
        </div>
        <Space>
          <Button
            type="primary"
            onClick={() => handleSort(dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => { handleReset(clearFilters, setSelectedKeys, dataIndex); setdisableBtn(true); }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />
    ),

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'User Name',
      dataIndex: 'createdAgentData',
      key: 'createdAgentData',
      align: 'center',
      width: '50',
      fixed: 'left',
      ...getColumnSearchProps("createdAgentData"),
      render: (text, record) => (
        <div>
          <Text>{record?.createdAgentData?.fullName || ""}</Text>
          <p style={{ fontSize: 12, color: 'grey' }}>{record?.createdAgentData?.email || ""}</p>
        </div>
      )
    },
    {
      title: 'Created By',
      dataIndex: 'createdByData',
      key: 'createdByData',
      align: 'center',
      ...getColumnSearchProps("createdByData"),
      render: (text, record) => (
        <div>
          <Text>{record?.createdByData?.fullName || ""}</Text>
          <p style={{ fontSize: 12, color: 'grey' }}>{record?.createdByData?.email || ""}</p>
        </div>
      )
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      ...getSort("createdAt"),
      render: (text) => (
        <div>
          <div>{moment(text).format("MMMM D, YYYY hh:mm A")}</div>
        </div>
      )
    },
    {
      title: 'Created Through',
      dataIndex: 'createdThrough',
      key: 'createdThrough',
      align: 'center',
      ...getSort('createdThrough'),
      render: (text) => (
        <p style={{ color: text === "ADMIN" ? "orange" : "green", fontWeight: 'bold' }}>
          {text}
        </p>
      )
    },
    {
      title: 'Platform Used',
      dataIndex: 'platformUsed',
      key: 'platformUsed',
      align: 'center',
      ...getSort('platformUsed'),
      render: (text) => (
        <Text>{text}</Text>
      )
    },
  ]

  return (
    <div id='logs'>
      {
        getLogsLoading ?
          <Loading />
          :
          <>
            <div className='headerDiv'>
              <div className='resetBtn'>
                <Button onClick={handleResetFilter} disabled={Object.keys(query).filter(key => key !== 'logType' && key !== 'page' && key !== 'limit').length === 0}>Reset Filter</Button>
              </div>
              <div className='titleDiv'>
                <h1>USER CREATED LOGS</h1>
              </div>
              <div className='blankDiv'></div>
            </div>
            <Table
              dataSource={getLogsData}
              columns={columns}
              bordered
              pagination={{
                position: ["bottomCenter"],
                showSizeChanger: false,
                pageSize: 5,
                total: totalRecords,
                current: currentPage,
                onChange: (page) => {
                  setCurrentPage(page)
                },
              }}
              scroll={{
                y: 480,
                x: 1000,
              }}
            />
          </>
      }
    </div>
  )
}

export default AgentCreatedLogs
