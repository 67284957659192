import React from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, message } from "antd";
import { checkIfDisabled, convertDate, createOfferContractTypes, dateData, noChangeCheckboxContractTypes } from "../../Common/commondata/commonData";
import { features } from "./features";
import setDayOfWeek from "./helpers/setDayOfWeek";
import accounting from "accounting";
import calculatePriceTable from "./helpers/calculatePriceTable";
import { smallHelpersFun } from "./helpers/smallHeperFunctions";
import { OtherTemplateDisabledFields, TableFourinputBox } from "../../Common/commondata/cbsrFields";
import { formatPhoneNumber } from "../../Common/utils/universalFuntions";
import moment from "moment";
const { confirm } = Modal;

export const validateEmail = (email) => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(String(email).toLowerCase());
};
const showConfirm = (event, currentUrlId, createOfferData, handleInputSave, specificData) => {
  if (event?.target?.checked) {
    confirm({
      title: "Are you sure to fill the dates automatically?",
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      content: "Your manually filled dates will be lost. Do you still want to continue?",
      onOk() {
        handleDateFillFunction(event, currentUrlId, createOfferData, handleInputSave, specificData);
      },
      onCancel() {
        let allCheckBoxex = document.querySelectorAll(`input[name="AutoFillDates"]`);
        allCheckBoxex?.forEach((el) => {
          el.checked = false;
        });
      },
    });
  }
};


export function handleToggleAutoFillDates(event = {}, currentUrlId, handleInputSave, specificData) {
  if (event.target.checked) {
    let closingDate = JSON.parse(localStorage.getItem(currentUrlId))?.ClosingDate;
    closingDate = !isNaN(Date.parse(closingDate?.value))  ? moment(closingDate?.value) : !isNaN(Date.parse(closingDate))? moment(closingDate): null;
    let originalContractDate = JSON.parse(localStorage.getItem(currentUrlId))?.OriginalContractDate || moment();
    originalContractDate = moment(originalContractDate);
    // console.log("closingDate", moment(closingDate).format("MM-DD-YYYY"),moment(originalContractDate).format("MM-DD-YYYY") )
    // Checking if the difference is less than 29 days
    if (closingDate && closingDate.diff(originalContractDate, 'days') < 29) {
      message.info("Please select a closing date at least 30 days from the document initiation date to fill Table 3.1 dates automatically.", 4);
      event.target.checked = false;
      return;
    }
    showConfirm(event, currentUrlId, handleInputSave, specificData);
  } else {
    handleDateFillFunction(event, currentUrlId, handleInputSave, specificData);
  }
}

// Offers all the dates filled automattically
export function handleDateFillFunction(event, currentUrlId, handleInputSave, specificData) {
  if (!event.target.checked) {
    smallHelpersFun.updateElements("checkbox", `input[name=${event.target.name}]`, false);
    specificData[event.target.name] = "";
    let mainLocalData = JSON.parse(localStorage.getItem(currentUrlId));
    mainLocalData[event?.target?.name] = "";
    localStorage.setItem(currentUrlId, JSON.stringify(mainLocalData));
    dateData.forEach((ele) => {
      let localData = JSON.parse(localStorage.getItem(currentUrlId));
      if (ele.canRemoved) {
        let eleValue = localData[ele.keyName];
        if (eleValue) {
          setDayOfWeek({
            name: ele.keyName,
            value: "",
          });
          smallHelpersFun.updateElements("input", `input[name=${ele.keyName}]`, "");
          if (ele.type === 'date') {
            specificData[ele.keyName] = {
              type: "text",
              value:"N/A"
            }
            localData[`${ele.keyName}`] = {
              type: "text",
              value:"N/A"
            }
          } else {
            specificData[ele.keyName] = ""
            localData[`${ele.keyName}`] = ""
          }
          localStorage.setItem(currentUrlId, JSON.stringify(localData));
        }
      }
    });
    handleInputSave(specificData);
  } else {
    smallHelpersFun.updateElements("checkbox", `input[name=${event.target.name}]`, true);
    specificData[event?.target?.name] = event?.target?.value;
    let mainLocalData = JSON.parse(localStorage.getItem(currentUrlId));
    mainLocalData[event?.target?.name] = event?.target?.value;
    localStorage.setItem(currentUrlId, JSON.stringify(mainLocalData));
    features.autoFillDateLogic(currentUrlId, "ToggleCheckbox", specificData);
    handleInputSave(specificData);
  }
}

export const ctrlShiftKey = (e, keyCode) => {
  return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
};

export const d = {
  el: {
    // get Element/s capital A is all elements
    id: (id) => document.getElementById(id),
    q: (selector) => document.querySelector(selector),
    qA: (selector) => document.querySelectorAll(selector),
    clA: (className) => document.getElementsByClassName(className),
    nA: (name) => document.getElementsByName(name),
    tgA: (tagName) => document.getElementsByTagName(tagName),

    // create Element
    Cr8: (tagName) => document.createElement(tagName),

    // remove Element
    r: (element) => element.remove(),

    // style an element
    s: (element, styles) => {
      for (let property in styles) {
        element.style[property] = styles[property];
      }
    },
    sM: (elements, styles) => {
      for (let i = 0; i < elements.length; i++) {
        for (let property in styles) {
          elements[i].style[property] = styles[property];
        }
      }
    },

    // attributes M is multiple attributes
    at: {
      s: (attributeName, value, element) => element.setAttribute(attributeName, value),
      g: (attributeName, element) => element.getAttribute(attributeName),
      sM: (attributes, element) => {
        for (let attribute in attributes) {
          element.setAttribute(attribute, attributes[attribute]);
        }
      },
      gM: (attributesArray, element) => {
        const attributes = {};
        for (let i = 0; i < attributesArray.length; i++) {
          attributes.push(element.getAttribute(attributesArray[i]));
        }
        return attributes;
      },
    },

    // append
    ap: (child, element) => element.append(child),

    // add eventListener
    ev: {
      a: (eventType, callback, element) => {
        element.addEventListener(eventType, callback);
      },
      r: (eventType, callback, element) => {
        element.removeEventListener(eventType, callback);
      },
    },
  },

  // localStorage
  l: {
    // setItem s -> non stringified, sS  -> stringified
    s: (itemName, item) => localStorage.setItem(itemName, item),
    sS: (itemName, item) => localStorage.setItem(itemName, JSON.stringify(item)),
    // getItem g -> non parsed, gP -> parsed
    g: (itemName) => localStorage.getItem(itemName),
    gP: (itemName) => JSON.parse(localStorage.getItem(itemName) || {}),
  },

  // consoles
  lg: function () {},
};

export function makeAllFieldsDisabled(currentUrlId, rootDocData) {
  const { openFor, contractType, RTDInitiator, signatureInfo, builtForSection, createOrEditTemplate, isOfferClone, buyerDocumentStatus, signable, buyerIds, checkForDisable } = rootDocData || {};

  let localData = JSON.parse(localStorage.getItem(currentUrlId));
  if (!localData) {
    return;
  }
  const inputElements = document.querySelectorAll("input, textarea");
  const buyerAddButton = document.querySelector(`[data-buyer-edit-icon]`);
  const sellerAddButton = document.querySelector(`[data-seller-edit-icon]`);
  const addDocsClausesBtns = document.querySelectorAll(`[data-add-docclause]`);
  const addTitleOfficeBtn = document.querySelectorAll(`[data-add-title-office]`);
  const buyerForms = document.getElementById("buyerAgentFields");
  const sellerForms = document.getElementById("sellerAgentFields");
  const signatureBlocks = document.querySelectorAll(".signature-block");
  const ListingPeriodBeginDate = document.getElementsByName("ListingPeriodBeginDate");
  const ListingPeriodEndByDate = document.getElementsByName("ListingPeriodEndByDate");
  const buyersSignWithDrawBlock = document.querySelector(`input[name=LetBuyersSignWithDrawBlock]`);
  const lbpdBuyerEditableFields = document.getElementsByName("BuyerHasElectedTo");

  if (ListingPeriodBeginDate && ListingPeriodEndByDate && ["ERTB", "ERTS", "ERTL", "ETCT"].includes(contractType)) {
    const oneDayBefore = smallHelpersFun.adjustDate(ListingPeriodEndByDate[0]?.value, -1);
    const oneDayAfter = smallHelpersFun.adjustDate(ListingPeriodBeginDate[0]?.value, 1);
    if (oneDayBefore) {
      ListingPeriodBeginDate.forEach(function (element) {
        element.max = oneDayBefore;
      });
    }
    if (oneDayAfter) {
      ListingPeriodEndByDate.forEach(function (element) {
        element.min = oneDayAfter;
      });
    }
  }
  if (["LCAE", "LCAES", "LCAES_ERTL", "LCAES_ETCT"].includes(contractType) && ListingPeriodEndByDate) {
    let startDate = localData["ListingPeriodBeginDate"];
    const oneDayAfter = smallHelpersFun.adjustDate(startDate, 1);
    if (oneDayAfter) {
      ListingPeriodEndByDate.forEach(function (element) {
        element.min = oneDayAfter;
      });
    }
  }
  let buyersCount = 0;
  buyerIds?.forEach((el) => {
    if (el.isCorp) {
      buyersCount += el.signers?.length;
    } else {
      buyersCount += 1;
    }
  });
  const anyOneSigned = (signatureInfo || []).length > 0;
  let anyBuyerSigned = false,
    anySellerSigned = false,
    allBuyersSigned = false;

  signatureInfo?.forEach((element) => {
    if (element.roleType === "BUYER" || element.roleType === "BUYER_AGENT") {
      anyBuyerSigned = true;
    } else {
      anySellerSigned = true;
    }
  });
  if (buyerDocumentStatus === "SUBMITTED") {
    allBuyersSigned = true;
  }
  const conditionalDisableElements = (forms, effect) => {
    if (forms) {
      // console.log("forms", forms, effect);
      forms.querySelectorAll("input, textarea")?.forEach((element) => {
        element.style.pointerEvents = effect;
      });
    }
  };
  const disableElements = (elements, effect) => {
    elements?.forEach((element) => {
      if (element) {
        element.style.pointerEvents = effect;
      }
    });
  };
  const disableAllElements = () => {
    disableElements(inputElements, "none");
    disableElements(addDocsClausesBtns, "none");
    disableElements(addTitleOfficeBtn, "none");
    disableElements([buyerAddButton, sellerAddButton], "none");
    conditionalDisableElements(buyerForms, "none");
    conditionalDisableElements(sellerForms, "none");
  };

  if (checkForDisable?.disabled || anyOneSigned || openFor === "BUYER" || (openFor === "SELLER" && contractType !=="SPD") || (openFor === "SELLER_AGENT" && !allBuyersSigned && isOfferClone) || builtForSection === "READ_ONLY_DOCUMENT" || localData["invalidated"] || localData["onlyTemplateview"] === true) {
    disableAllElements();
  }
  if (builtForSection === "TEMPLATE") {
    if (createOrEditTemplate) {
      disableElements([buyerAddButton, sellerAddButton], "none");
      disableElements(signatureBlocks, "none");
      TableFourinputBox.forEach((el) => {
        let tableFourPointOne = document.getElementsByName(el);
        tableFourPointOne.forEach((e) => {
          e.style.pointerEvents = "none";
        });
      });
      OtherTemplateDisabledFields.forEach((el) => {
        let tableThreePointOneLast = document.getElementsByName(el);
        tableThreePointOneLast.forEach((e) => {
          e.style.pointerEvents = "none";
        });
      });
    } else {
      disableAllElements();
    }
  }

  if (createOfferContractTypes.includes(rootDocData?.contractType)) {
    let sellerBrokerNameEditIcon = document.getElementById("sellerBrokerNameEditIcon");
    if (!anyOneSigned && openFor === "BUYER" && signable && !localData["Sellers"]?.length) {
      disableElements([sellerAddButton], "auto");
    }
    
    if (openFor === "SELLER_AGENT" && !anySellerSigned) {
      disableElements([sellerAddButton], "auto");
      conditionalDisableElements(sellerForms, "auto");
      const corpSignersList = document.getElementById("corpSignersList")
      conditionalDisableElements(corpSignersList, "auto");
      // Showing the edit icon for seller broker name on offer forms
      if (sellerBrokerNameEditIcon) sellerBrokerNameEditIcon.style.display = 'block';
    } else {
      // disableElements([sellerAddButton], "none");
      conditionalDisableElements(sellerForms, "none");
      if (sellerBrokerNameEditIcon) sellerBrokerNameEditIcon.style.display = 'none';
    }
  }

  if (!createOfferContractTypes.includes(contractType)) {
    if (openFor === "BUYER_AGENT" && RTDInitiator === "BUYER" && !anyOneSigned) {
      conditionalDisableElements(buyerForms, "auto");
      conditionalDisableElements(sellerForms, "none");
    }
    if (openFor === "SELLER_AGENT" && RTDInitiator === "SELLER" && !anyOneSigned) {
      conditionalDisableElements(buyerForms, "none");
      conditionalDisableElements(sellerForms, "auto");
    }
    if (openFor === "SELLER_AGENT" && RTDInitiator === "BUYER" && !anySellerSigned) {
      conditionalDisableElements(buyerForms, "none");
      conditionalDisableElements(sellerForms, "auto");
    }
    if (openFor === "BUYER_AGENT" && RTDInitiator === "SELLER" && !anyBuyerSigned) {
      conditionalDisableElements(buyerForms, "auto");
      conditionalDisableElements(sellerForms, "none");
    }

    if (buyersSignWithDrawBlock && openFor === "BUYER_AGENT" && RTDInitiator === "BUYER" && buyersCount === signatureInfo?.length) {
      buyersSignWithDrawBlock.style.pointerEvents = "auto";
    } else if (buyersSignWithDrawBlock) {
      buyersSignWithDrawBlock.style.pointerEvents = "none";
    }
    // LBPD form > Buyers Acknowledgement fields should be editable by Buying side only
    if (contractType === "LBPD" && lbpdBuyerEditableFields) {
      if ((openFor === 'BUYER' || openFor === 'BUYER_AGENT') && !anyBuyerSigned) {
        disableElements(lbpdBuyerEditableFields, "auto"); // Enable for buying side
      } else {
        disableElements(lbpdBuyerEditableFields, "none"); // Disable for selling side
      }
    }
  }

  if (contractType === "CI" && signable) {
    let hasTitleCompanyRole = false;
    signatureInfo?.forEach(info => {
      if (info?.roleType === 'TITLE_COMPANY') {
        hasTitleCompanyRole = true;
      }
    });
    const signerTitleInputField = document.getElementById("SignerTitleField");
    if (signerTitleInputField && openFor === "TITLE_COMPANY") {
      if (hasTitleCompanyRole) {
        signerTitleInputField.style.pointerEvents = "none";
      } else {
        signerTitleInputField.style.pointerEvents = "auto";
      }
    }else if(signerTitleInputField && openFor !== "TITLE_COMPANY"){
      signerTitleInputField.style.pointerEvents = "none";
    }
  }
}

export function handleToggleAutoPriceCalculation(event, currentUrlId, handleInputSave, specificData) {
  const fieldsToGet = ["PurchasePrice", "EarnestMoney", "NewLoan", "AssumptionBalance", "PrivateFinancing", "SellerFinancing"];
  const tableValues = {};
  const updateValuesAndDOM = (values, action) => {
    for (const key in values) {
      specificData[key] = values[key];
      document.getElementsByName(key)?.forEach((element) => {
        element.value = values[key];
        element.parentNode.dataset.replicatedValue = values[key];
      });
    }
    const localData = JSON.parse(localStorage.getItem(currentUrlId)) || {};
    localStorage.setItem(currentUrlId, JSON.stringify({ ...localData, ...specificData }));
    handleInputSave(specificData);
  };

  fieldsToGet.forEach((field) => {
    let elements;
    if (event.target.checked) {
      elements = [document.querySelector(`[name="${field}"]`)];
    } else {
      elements = document.getElementsByName(field);
    }
    const value = elements?.[0]?.value ? accounting.unformat(elements[0].value) : event.target.checked ? 0.0 : 0;
    tableValues[field] = field === "PurchasePrice" ? value : 0.0;
  });
  const updatedValues = calculatePriceTable(tableValues, event.target.checked ? undefined : "ChecboxUnchecked");
  updateValuesAndDOM(updatedValues);
}

export function handleSignatureCompletion(signatureBlock) {
  // console.log("parentElement", signatureBlock);
  const { _id, sign, initials, timeStamp, roleType, key } = signatureBlock;
  let signatureDate = convertDate(timeStamp);
  let parentElement;
  if (key && _id) {
    parentElement = document.getElementById(roleType + "_" + _id + "_key_" + key);
  } else {
    parentElement = document.getElementById(roleType + "_" + (_id || roleType));
    if(!parentElement){
      parentElement = document.getElementById(roleType + "_" + (_id || roleType) + "_key_" + key);
    }
  }

  if (parentElement) {
    const verifiedSignDiv = document.createElement("div");
    verifiedSignDiv.className = "signature-block signature-wrapper";
    verifiedSignDiv.innerHTML = `
    <div class="signature-block signature-img">
      <img src="${sign}" alt="Sign"/>
    </div>
    <div class="signature-block signature-verification">RE-Sure Verified<br /> ${initials !== undefined ? timeStamp + initials : timeStamp}</div>
  `;
    parentElement.innerHTML = "";
    parentElement.appendChild(verifiedSignDiv);
  }
  let signatureDateElement;
  if (key && _id) {
    signatureDateElement = document.querySelector(`[data-signatureDate="${roleType + "_" + _id + "_key_" + key}"]`);
  } else {
    signatureDateElement = document.querySelector(`[data-signatureDate="${roleType + "_" + (_id || roleType)}"]`);
  }
  //  console.log("signatureDateElement", signatureDateElement);
  if (signatureDateElement) {
    signatureDateElement.textContent = signatureDate || "";
  }
}

export function showCompanyDetails(td) {
  const agentOfficeLogo = document.querySelector("#agentOfficeLogo");
  const setLogoAttributes = () => {
    agentOfficeLogo.innerHTML = "";
    const { officeDetails: { officeLogo } = {} } = td || {};
    if (!officeLogo) {
      agentOfficeLogo?.remove();
      return;
    }
    let logo = document.createElement("img");
    logo.setAttribute("src", officeLogo);
    // Seting a temporary image to calculate our image original dimensions
    const tempImage = new Image();
    tempImage.src = logo.src;
    tempImage.onload = () => {
      // Calculating aspect ratio
      const aspectRatio = tempImage.width / tempImage.height;
      const desiredWidth = 100;
      const calculatedHeight = desiredWidth / aspectRatio;
      logo.setAttribute("width", `${desiredWidth}px`);
      logo.setAttribute("height", `${calculatedHeight}px`);
      agentOfficeLogo?.append(logo);
    };
  };

  if (agentOfficeLogo && td?.officeDetails) {
    setLogoAttributes();
  }

  const setFieldTextByID = (fieldId, value) => {
    const field = document.querySelector(`#${fieldId}`);
    if (field) field.innerText = value || "";
  };
  setFieldTextByID("AGENT_NAME", td?.BuyerBrokerName || "");
  setFieldTextByID("AGENT_BROKER_ADDRESS", td?.officeDetails?.nameandAddress || "");
  setFieldTextByID("AGENT_EMAIL", td?.BuyerBrokerEmailAddress || "");
  setFieldTextByID("AGENT_PHONE", formatPhoneNumber(td?.BuyerBrokerPhoneNumber));
}

let currentConfirmationModal = null;
const showManualEntryDateConfirmation = ({  messageTitle = "", isClosingDateField, inputValue, handleDataChange }) => {
  if (currentConfirmationModal) {
    currentConfirmationModal.destroy();
    currentConfirmationModal = null;
  }
  currentConfirmationModal = confirm({
    title: messageTitle,
    okText: "Yes",
    cancelText: "No",
    icon: <ExclamationCircleOutlined />,
    content: "Do you still want to proceed?",
    onOk() {
      let formattedData = {
        type: inputValue ? "date" : "text",
        value: inputValue || "N/A",
      };
      if (isClosingDateField) formattedData = formattedData.value;
      handleDataChange({formattedData})
    },
    onCancel() {
    },
  });
};



export function handleManualEntryDateValidation({ event = {},  inputValue, handleDataChange, rootDocData }) {
  try {
    const isClosingDateField = event?.target?.classList?.contains("closing-date-input");
    const parseDateToTimestamp = (dateString) => moment(dateString).valueOf();

    const getTextBeforeInput = (inputElement) => {
      const previousElement = inputElement?.parentElement?.previousElementSibling;
      return previousElement ? previousElement.textContent?.trim() : "";
    };
     // Checking for ClosingDate validation
    if (event.target.hasAttribute("data-checkDateValidation")) {
      const closingDateElement = document.querySelector(`[name="${event.target?.getAttribute("data-checkDateValidation")}"]`);
      // console.log("nextElement.value", closingDateElement.value, nextElement.value);
      if (closingDateElement && parseDateToTimestamp(closingDateElement.value) < parseDateToTimestamp(inputValue)) {
        const fieldName = getTextBeforeInput(closingDateElement);
        let messageTitle = `The selected date should not be after the ${fieldName}.`;
        showManualEntryDateConfirmation({  messageTitle, isClosingDateField, inputValue, handleDataChange });
        return;
      }
    }
  
    // Checking for group validation
    if (event.target.hasAttribute("data-dateValidationgroup")) {
      const groupElements = Array.from(document.querySelectorAll(`[data-dateValidationgroup="${event.target.getAttribute("data-dateValidationgroup")}"]`));
      if (event.target.hasAttribute("data-dateorder")) {
        const currentOrder = parseInt(event.target.dataset.dateorder);
        const previousElement = groupElements?.find((el) => el.dataset.dateorder === (currentOrder - 1)?.toString());
        const nextElement = groupElements?.find((el) => el.dataset.dateorder === (currentOrder + 1)?.toString());
        // console.log("nextElement.value",  nextElement?.value);
        const currentDateTimestamp = parseDateToTimestamp(inputValue);
        let messageTitle = ``;
        if (previousElement && currentDateTimestamp < parseDateToTimestamp(previousElement.value)) {
          const fieldName = getTextBeforeInput(previousElement);
          messageTitle = `The selected date should not be before the ${fieldName}.`;
        } else if (nextElement && currentDateTimestamp > parseDateToTimestamp(nextElement.value)) {
          const nextFieldName = getTextBeforeInput(nextElement);
          messageTitle = `The selected date should not be after the ${nextFieldName}.`;
        } else if (previousElement && nextElement && parseDateToTimestamp(previousElement.value) > currentDateTimestamp && currentDateTimestamp < parseDateToTimestamp(nextElement.value)) {
          const previousFieldName = getTextBeforeInput(previousElement);
          const nextFieldName = getTextBeforeInput(nextElement);
          messageTitle = `The selected date should not be before the ${previousFieldName} ${previousFieldName && nextFieldName ? " and " : ""}after the ${nextFieldName}.`;
        }
  
        if (messageTitle) {
          showManualEntryDateConfirmation({  messageTitle, isClosingDateField, inputValue, handleDataChange });
          return;
        }
      }
    }
    // console.log("everyThing is correct", inputValue);
    let formattedData = {
      type: inputValue ? "date" : "text",
      value: inputValue?inputValue:noChangeCheckboxContractTypes.includes(rootDocData?.contractType) ? "" :"N/A",
    };
    if (isClosingDateField) formattedData = formattedData.value;
    handleDataChange({ formattedData });
  } catch (error) {
    console.log("error happens in manually Entered date field", error?.message)
  }
}

export function handleConfirmModal({event, specificData, removeSection, handleInputSave, currentUrlId}) {
  if (event.target.checked) {
    let checkReference = false;
    if (event.target.name === event.target.parentNode.parentNode.id) {
      const containerId = document.getElementById(event.target.name).id || event.target.parentNode.parentNode.id;
      checkReference = hasReferencedElement({containerId, currentUrlId});
    }
    confirm({
      title: checkReference ? `This Section also contains the reference from the other parts of the document.` : `Remove Section`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure want to remove this Section?",
      onOk() {
        event.target.checked = true;
        const dirtyFieldsUpdate = removeSection(event.target);
        for (const key in dirtyFieldsUpdate) {
          specificData[key] = dirtyFieldsUpdate[key];
        }
        specificData[event.target.name] = [event.target.value];
        // console.log("dirtyFieldsUpdate", dirtyFieldsUpdate);
        handleInputSave(specificData);
      },
      onCancel() {
        event.target.checked = false;
      },
    });
  } else {
    const dirtyFieldsUpdate = removeSection(event.target);
    for (const key in dirtyFieldsUpdate) {
      specificData[key] = dirtyFieldsUpdate[key];
    }
    specificData[event.target.name] = "";
    return specificData;
  }
}

function hasReferencedElement({containerId, currentUrlId}) {
  const container = document.getElementById(containerId);
  if (container) {
    const elements = Array.from(container.querySelectorAll("[data-referenced-with-table]"));
    const localData = JSON.parse(localStorage.getItem(currentUrlId)) || {};
    const valueArray = elements
      .map((ele) => {
        const val = ele.dataset.referencedWithTable;
        // check if the respective referenced elements have value or date present in the table
        if (localData[val] && localData[val] !== "" && localData[val] !== 0 && localData[val] !== "0.00") {
          ele.style.backgroundColor = "rgb(64, 255, 53)";
          setTimeout(() => {
            ele.style.backgroundColor = "";
          }, 5000);
          return val;
        } else {
          return null;
        }
      })
      .filter((val) => val !== null);
    return valueArray.length > 0;
  }
  return false;
}

export function checkListingContainer(fieldToBeChecked, type) {
  const listingContainer = document.querySelector(".listingTableContainer");

  if (!listingContainer) {
    return null;
  }

  const inputFields = Array.from(listingContainer.querySelectorAll("input, textarea"));
  const listingCompanyDetailKeysArr = inputFields?.filter((e) => e.type !== "checkbox").map((field) => field.name) || [];

  switch (type) {
    case "checkIsPresent":
      return listingCompanyDetailKeysArr?.includes(fieldToBeChecked);
    case "getFieldNames":
      return listingCompanyDetailKeysArr;
    default:
      return null;
  }
}

export function checkSellingContainer(fieldToBeChecked, type) {
  const sellingContainer = document.querySelector(".sellingTableContainer");

  if (!sellingContainer) {
    return null;
  }

  const inputFields = Array.from(sellingContainer?.querySelectorAll("input, textarea"));
  const sellingCompanyDetailKeysArr = inputFields?.filter((e) => e.type !== "checkbox").map((field) => field.name) || [];

  switch (type) {
    case "checkIsPresent":
      return sellingCompanyDetailKeysArr?.includes(fieldToBeChecked);
    case "getFieldNames":
      return sellingCompanyDetailKeysArr;
    default:
      return null;
  }
}

export function pasteListingCoDataIntoSellingCo({localData, specificData}) {
  localData["BuyerBrokerFirmName"] = localData["SellerBrokerFirmName"];
  localData["BuyerBrokerFirmLicenseNumber"] = localData["SellerBrokerFirmLicenseNumber"];
  localData["BuyerBrokerAddress1"] = localData["SellerBrokerAddress1"];
  localData["BuyerBrokerAddress2"] = localData["SellerBrokerAddress2"];
  localData["BuyerBrokerFirmState"] = localData["SellerBrokerFirmState"];
  localData["BuyerBrokerFirmCity"] = localData["SellerBrokerFirmCity"];
  localData["BuyerBrokerFirmZipCode"] = localData["SellerBrokerFirmZipCode"];
  localData["BuyerBrokerCommission"] = localData["SellerBrokerCommission"];
  localData["BuyerBrokerCommissionPaidBy"] = localData["SellerBrokerCommissionPaidBy"];
  localData["BuyerBrokerName"] = localData["SellerBrokerName"];
  localData["BuyerBrokerLicenseNumber"] = localData["SellerBrokerLicenseNumber"];
  localData["BuyerBrokerPhoneNumber"] = localData["SellerBrokerPhoneNumber"];
  localData["BuyerBrokerEmailAddress"] = localData["SellerBrokerEmailAddress"];
  localData["BuyerBrokerCellPhoneNumber"] = localData["SellerBrokerCellPhoneNumber"];
  localData["BuyerBrokerFaxNumber"] = localData["SellerBrokerFaxNumber"];
  localData["BuyerBrokerOtherRequest"] = localData["SellerBrokerOtherRequest"];

  specificData = {
    ...specificData,
    BuyerBrokerFirmLicenseNumber: localData["BuyerBrokerFirmLicenseNumber"],
    BuyerBrokerAddress1: localData["BuyerBrokerAddress1"],
    BuyerBrokerAddress2: localData["BuyerBrokerAddress2"],
    // BuyerBrokerFirmState: localData["BuyerBrokerFirmState"],
    // BuyerBrokerFirmCity: localData["BuyerBrokerFirmCity"],
    // BuyerBrokerFirmZipCode: localData["BuyerBrokerFirmZipCode"],
    BuyerBrokerCommission: localData["BuyerBrokerCommission"],
    BuyerBrokerCommissionPaidBy: localData["BuyerBrokerCommissionPaidBy"],
    BuyerBrokerName: localData["BuyerBrokerName"],
    BuyerBrokerLicenseNumber: localData["BuyerBrokerLicenseNumber"],
    BuyerBrokerPhoneNumber: localData["BuyerBrokerPhoneNumber"],
    BuyerBrokerEmailAddress: localData["BuyerBrokerEmailAddress"],
    BuyerBrokerCellPhoneNumber: localData["BuyerBrokerCellPhoneNumber"],
    BuyerBrokerFaxNumber: localData["BuyerBrokerFaxNumber"],
    BuyerBrokerOtherRequest: localData["BuyerBrokerOtherRequest"],
  };
  return specificData;
}

export function pasteSellingCoDataIntoListingCo({localData, specificData}) {
  localData["SellerBrokerFirmName"] = localData["BuyerBrokerFirmName"];
  localData["SellerBrokerFirmLicenseNumber"] = localData["BuyerBrokerFirmLicenseNumber"];
  localData["SellerBrokerAddress1"] = localData["BuyerBrokerAddress1"];
  localData["SellerBrokerAddress2"] = localData["BuyerBrokerAddress2"];
  // localData["SellerBrokerFirmState"] = localData["BuyerBrokerFirmState"];
  // localData["SellerBrokerFirmCity"] = localData["BuyerBrokerFirmCity"];
  // localData["SellerBrokerFirmZipCode"] = localData["BuyerBrokerFirmZipCode"];
  localData["SellerBrokerCommission"] = localData["BuyerBrokerCommission"];
  localData["SellerBrokerCommissionPaidBy"] = localData["BuyerBrokerCommissionPaidBy"];
  localData["SellerBrokerName"] = localData["BuyerBrokerName"];
  localData["SellerBrokerLicenseNumber"] = localData["BuyerBrokerLicenseNumber"];
  localData["SellerBrokerPhoneNumber"] = localData["BuyerBrokerPhoneNumber"];
  localData["SellerBrokerEmailAddress"] = localData["BuyerBrokerEmailAddress"];
  localData["SellerBrokerCellPhoneNumber"] = localData["BuyerBrokerCellPhoneNumber"];
  localData["SellerBrokerFaxNumber"] = localData["BuyerBrokerFaxNumber"];
  localData["SellerBrokerOtherRequest"] = localData["BuyerBrokerOtherRequest"];

  specificData = {
    ...specificData,
    SellerBrokerFirmName: localData["SellerBrokerFirmName"],
    SellerBrokerFirmLicenseNumber: localData["SellerBrokerFirmLicenseNumber"],
    SellerBrokerAddress1: localData["SellerBrokerAddress1"],
    SellerBrokerAddress2: localData["SellerBrokerAddress2"],
    // SellerBrokerFirmState: localData["SellerBrokerFirmState"],
    // SellerBrokerFirmCity: localData["SellerBrokerFirmCity"],
    // SellerBrokerFirmZipCode: localData["SellerBrokerFirmZipCode"],
    SellerBrokerCommission: localData["SellerBrokerCommission"],
    SellerBrokerCommissionPaidBy: localData["SellerBrokerCommissionPaidBy"],
    SellerBrokerName: localData["SellerBrokerName"],
    SellerBrokerLicenseNumber: localData["SellerBrokerLicenseNumber"],
    SellerBrokerPhoneNumber: localData["SellerBrokerPhoneNumber"],
    SellerBrokerEmailAddress: localData["SellerBrokerEmailAddress"],
    SellerBrokerCellPhoneNumber: localData["SellerBrokerCellPhoneNumber"],
    SellerBrokerFaxNumber: localData["SellerBrokerFaxNumber"],
    SellerBrokerOtherRequest: localData["SellerBrokerOtherRequest"],
  };
  return specificData;
}

export function updateIndividualChangedField({titleCompanySelectedBy, localData, fieldUpdated, specificData, value}) {
  if (titleCompanySelectedBy === "BUYER_AGENT") {
    if (fieldUpdated === "BuyerBrokerFirmName") {
      localData["SellerBrokerFirmName"] = value;
      specificData = {
        ...specificData,
        SellerBrokerFirmName: value,
      };
    }
    if (fieldUpdated === "BuyerBrokerFirmLicenseNumber") {
      localData["SellerBrokerFirmLicenseNumber"] = value;
      specificData = {
        ...specificData,
        SellerBrokerFirmLicenseNumber: value,
      };
    }
    if (fieldUpdated === "BuyerBrokerAddress1") {
      localData["SellerBrokerAddress1"] = value;
      specificData = {
        ...specificData,
        SellerBrokerAddress1: value,
      };
    }
    if (fieldUpdated === "BuyerBrokerAddress2") {
      localData["SellerBrokerAddress2"] = value;
      specificData = {
        ...specificData,
        SellerBrokerAddress2: value,
      };
    }
    // if (fieldUpdated === "BuyerBrokerFirmState") {
    //   localData["SellerBrokerFirmState"] = value;
    //   specificData = {
    //     ...specificData,
    //     SellerBrokerFirmState: value,
    //   };
    // }
    // if (fieldUpdated === "BuyerBrokerFirmCity") {
    //   localData["SellerBrokerFirmCity"] = value;
    //   specificData = {
    //     ...specificData,
    //     SellerBrokerFirmCity: value,
    //   };
    // }
    // if (fieldUpdated === "BuyerBrokerFirmZipCode") {
    //   localData["SellerBrokerFirmZipCode"] = value;
    //   specificData = {
    //     ...specificData,
    //     SellerBrokerFirmZipCode: value,
    //   };
    // }
    if (fieldUpdated === "BuyerBrokerCommission") {
      localData["SellerBrokerCommission"] = value;
      specificData = {
        ...specificData,
        SellerBrokerCommission: value,
      };
    }
    if (fieldUpdated === "BuyerBrokerCommissionPaidBy") {
      localData["SellerBrokerCommissionPaidBy"] = value;
      specificData = {
        ...specificData,
        SellerBrokerCommissionPaidBy: value,
      };
    }
    if (fieldUpdated === "BuyerBrokerName") {
      localData["SellerBrokerName"] = value;
      specificData = {
        ...specificData,
        SellerBrokerName: value,
      };
    }
    if (fieldUpdated === "BuyerBrokerLicenseNumber") {
      localData["SellerBrokerLicenseNumber"] = value;
      specificData = {
        ...specificData,
        SellerBrokerLicenseNumber: value,
      };
    }
    if (fieldUpdated === "BuyerBrokerPhoneNumber") {
      localData["SellerBrokerPhoneNumber"] = value;
      specificData = {
        ...specificData,
        SellerBrokerPhoneNumber: value,
      };
    }
    if (fieldUpdated === "BuyerBrokerEmailAddress") {
      localData["SellerBrokerEmailAddress"] = value;
      specificData = {
        ...specificData,
        SellerBrokerEmailAddress: value,
      };
    }
    if (fieldUpdated === "BuyerBrokerCellPhoneNumber") {
      localData["SellerBrokerCellPhoneNumber"] = value;
      specificData = {
        ...specificData,
        SellerBrokerCellPhoneNumber: value,
      };
    }
    if (fieldUpdated === "BuyerBrokerFaxNumber") {
      localData["SellerBrokerFaxNumber"] = value;
      specificData = {
        ...specificData,
        SellerBrokerFaxNumber: value,
      };
    }
    if (fieldUpdated === "BuyerBrokerOtherRequest") {
      localData["SellerBrokerOtherRequest"] = value;
      specificData = {
        ...specificData,
        SellerBrokerOtherRequest: value,
      };
    }
    return specificData;
  }
  if (titleCompanySelectedBy === "SELLER_AGENT") {
    if (fieldUpdated === "SellerBrokerFirmName") {
      localData["BuyerBrokerFirmName"] = value;
      specificData = {
        ...specificData,
        BuyerBrokerFirmName: value,
      };
    }
    if (fieldUpdated === "SellerBrokerFirmLicenseNumber") {
      localData["BuyerBrokerFirmLicenseNumber"] = value;
      specificData = {
        ...specificData,
        BuyerBrokerFirmLicenseNumber: value,
      };
    }
    if (fieldUpdated === "SellerBrokerAddress1") {
      localData["BuyerBrokerAddress1"] = value;
      specificData = {
        ...specificData,
        BuyerBrokerAddress1: value,
      };
    }
    if (fieldUpdated === "SellerBrokerAddress2") {
      localData["BuyerBrokerAddress2"] = value;
      specificData = {
        ...specificData,
        BuyerBrokerAddress2: value,
      };
    }
    // if (fieldUpdated === "SellerBrokerFirmState") {
    //   localData["BuyerBrokerFirmState"] = value;
    //   specificData = {
    //     ...specificData,
    //     BuyerBrokerFirmState: value,
    //   };
    // }
    // if (fieldUpdated === "SellerBrokerFirmCity") {
    //   localData["BuyerBrokerFirmCity"] = value;
    //   specificData = {
    //     ...specificData,
    //     BuyerBrokerFirmCity: value,
    //   };
    // }
    // if (fieldUpdated === "SellerBrokerFirmZipCode") {
    //   localData["BuyerBrokerFirmZipCode"] = value;
    //   specificData = {
    //     ...specificData,
    //     BuyerBrokerFirmZipCode: value,
    //   };
    // }
    if (fieldUpdated === "SellerBrokerCommission") {
      localData["BuyerBrokerCommission"] = value;
      specificData = {
        ...specificData,
        BuyerBrokerCommission: value,
      };
    }
    if (fieldUpdated === "SellerBrokerCommissionPaidBy") {
      localData["BuyerBrokerCommissionPaidBy"] = value;
      specificData = {
        ...specificData,
        BuyerBrokerCommissionPaidBy: value,
      };
    }
    if (fieldUpdated === "SellerBrokerName") {
      localData["BuyerBrokerName"] = value;
      specificData = {
        ...specificData,
        BuyerBrokerName: value,
      };
    }
    if (fieldUpdated === "SellerBrokerLicenseNumber") {
      localData["BuyerBrokerLicenseNumber"] = value;
      specificData = {
        ...specificData,
        BuyerBrokerLicenseNumber: value,
      };
    }
    if (fieldUpdated === "SellerBrokerPhoneNumber") {
      localData["BuyerBrokerPhoneNumber"] = value;
      specificData = {
        ...specificData,
        BuyerBrokerPhoneNumber: value,
      };
    }
    if (fieldUpdated === "SellerBrokerEmailAddress") {
      localData["BuyerBrokerEmailAddress"] = value;
      specificData = {
        ...specificData,
        BuyerBrokerEmailAddress: value,
      };
    }
    if (fieldUpdated === "SellerBrokerCellPhoneNumber") {
      localData["BuyerBrokerCellPhoneNumber"] = value;
      specificData = {
        ...specificData,
        BuyerBrokerCellPhoneNumber: value,
      };
    }
    if (fieldUpdated === "SellerBrokerFaxNumber") {
      localData["BuyerBrokerFaxNumber"] = value;
      specificData = {
        ...specificData,
        BuyerBrokerFaxNumber: value,
      };
    }
    if (fieldUpdated === "SellerBrokerOtherRequest") {
      localData["BuyerBrokerOtherRequest"] = value;
      specificData = {
        ...specificData,
        BuyerBrokerOtherRequest: value,
      };
    }
    return specificData;
  }
}

export function checkInCompleteSellers(clientsArray = []) {
  if (!clientsArray || !clientsArray?.length) {
    return true;
  }
  for (let ele of clientsArray) {
    if (ele?.isCorp && (!ele?.signers || !ele.signers?.length)) {
      return true;
    } else if (ele?.isCorp && ele?.signers?.length) {
      for (let el of ele.signers) {
        if (!el.personId) {
          return true;
        }
      }
    } else {
      if (ele?.isCorp === false && !ele?.personId) {
        return true;
      } else if (!ele.isCorp && !ele.personId && ele.key) {
        return true;
      }
    }
  }
  return false;
}

