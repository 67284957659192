/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { verificationHandle } from "./state/actions";
import { Layout, Card, Form, Checkbox, Button, Spin, Col, Row, Input, Select } from "antd";
import { EyeTwoTone, EyeInvisibleOutlined, LockOutlined } from "@ant-design/icons";
import { SuccessForm } from "../Common/components/SuccessForm";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { decryptQuery, decryptStrQuery } from "../Common/commondata/commonData";
import { cmsDataHandle } from "../CMS/state/actions";
import { accountAction } from "../Accounts/state/actions";
import PhoneInput from "react-phone-input-2";
const { Option } = Select;
const { Content } = Layout;
const marketingURL = process.env.REACT_APP_MARKETING_URL;
const apiURL = process.env.REACT_APP_WEB_BASE_URL;

const VerificationWithTerms = (props) => {
  useEffect(() => {
    if (props.networkConnection === false) {
      window.location.reload();
    }
  }, [props]);
  const dispatch = useDispatch();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const token = decryptStrQuery(query.get("key") || "");
  const userId = decryptQuery(query.get("sessionId") || "");
  const { getUserDetailsData, getUserProfileDataLoading } = useSelector((state) => state.account, shallowEqual);
  const { associationData, associationDataLoading } = useSelector((state) => state.cms);
  const [checkedValue, setCheckedValue] = useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(accountAction.getUserDetails({ token }));
  }, [token]);

  useEffect(() => {
    if (getUserDetailsData) {
      form.setFieldsValue(
        {
          phoneNumber: getUserDetailsData?.mobilePhoneNumber?.phoneNumber || getUserDetailsData?.phoneData?.phoneNumber || "",
          association: getUserDetailsData?.associationData?.label || "",
        }
      );
    }
  }, [getUserDetailsData]);

  const onFinish = (event={}) => {
    let payload = {
      password: event.password,
      isEmailVerified: true,
     ...(event.phoneNumber && { 
        phoneNumber: event.phoneNumber
      }),
     ...(event.association && { association: event.association }),
        userId
    };
    dispatch(verificationHandle.emailVerificationWithTerms(token, { payload }));
  };

  const validateTerms = (_, value) => {
    if (!checkedValue) {
      return Promise.reject(new Error("Please select Terms & Conditions and Privacy Policy!"));
    }
    return Promise.resolve();
  };

  const onChange = (e) => {
    setCheckedValue(e.target.checked);
  };
  const handleRedirect = async () => {
    window.location.href = `${apiURL}/login`;
  };
  const handleAssociation = () => {
    dispatch(cmsDataHandle.getAssociationData());
  };

  return (
    <Layout style={{ height: "100vh", padding: 0 }}>
      <Content
        style={{
          overflow: "auto",
          background: "aliceblue",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;",
        }}
      >
        <Card style={{ width: 600 }} title={getUserDetailsData?.isEmailVerified ? false : <div style={{ textAlign: "center", color: "#178DFA" }}>EMAIL VERIFICATION</div>}>
          {getUserProfileDataLoading ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "250px" }}>
              <Spin size="large" />
            </div>
          ) : getUserDetailsData?.isEmailVerified ? (
            <>
              <SuccessForm title={"Your email has been verified successfully. You can now start using RE-Sure."} flag={false} />
              <div style={{ textAlign: "center", marginBottom: "30px" }}>
                <Button type="primary" size="large" onClick={handleRedirect}>
                  Login
                </Button>
              </div>
            </>
          ) : (
            <Form layout="vertical" form={form} onFinish={onFinish} scrollToFirstError style={{ width: "100%" }}>
              <Form.Item>
                <Row gutter={[16, 16]} justify="space-between">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="password"
                      label="Password"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (getFieldValue("password") !== getUserDetailsData?.email && value?.length > 5 && value?.includes(" ") === false) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(new Error("Required minimum 6 characters password!"));
                            }
                          },
                        }),
                      ]}
                    >
                      <Input.Password prefix={<LockOutlined className="site-form-item-icon" style={{ marginRight: "15px" }} />} maxLength={20} type="password" placeholder="Enter Password" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} allowClear />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="confirmPassword"
                      label="Confirm Password"
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }

                            return Promise.reject(new Error("The password that you entered do not match!"));
                          },
                        }),
                      ]}
                    >
                      <Input.Password prefix={<LockOutlined className="site-form-item-icon" style={{ marginRight: "15px" }} />} type="password" maxLength={20} placeholder="Enter Password" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} allowClear />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Row gutter={[16, 16]} justify="space-between">
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    label={<span style={{ marginLeft: "11px" }}>Phone Number</span>}
                    name="phoneNumber"
                    hasFeedback
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value?.length) {
                            if (value?.length === 10) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error("Please Enter 10 digit Phone Number!"));
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <PhoneInput inputStyle={{ width: "100%" }} inputClass="phoneInput" country={"us"} onlyCountries={["us"]} maxLength="10" international={false} disableCountryCode={true} placeholder="Enter Phone Number" className="phone-input" style={{ width: "286px !important" }} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item name="association" label="Association" rules={[{ required: false, message: "" }]}>
                    {associationData && associationData?.length > 0 ? (
                      <Select style={{ width: "100%", height: "30px" }} placeholder="Select Association" onFocus={handleAssociation} showSearch filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {associationData?.map((element, index) => (
                          <Option key={index} value={element.key}>
                            {element.label}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <Select style={{ width: "100%", height: "30px" }} placeholder="Select Association" onFocus={handleAssociation}>
                        {associationDataLoading ? (
                          <div style={{ minHeight: "20vh", display: "flex", alignItems: "center", textAlign: "center" }}>
                            <Spin size="medium" />
                          </div>
                        ) : (
                          associationData?.map((element, index) => (
                            <Option key={index} value={element.key}>
                              {element.label}
                            </Option>
                          ))
                        )}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                style={{ marginBottom: "35px" }}
                name="termsAndConditions"
                rules={[
                  {
                    validator: validateTerms,
                  },
                ]}
              >
                <Checkbox onChange={onChange} defaultChecked={true}>
                  I agree to the{" "}
                  <a target="_blank" href={`${marketingURL}/termofservice`} rel="noreferrer">
                    Terms & Conditions
                  </a>{" "}
                  and{" "}
                  <a target="_blank" href={`${marketingURL}/privacypolicy`} rel="noreferrer">
                    Privacy Policy
                  </a>
                </Checkbox>
              </Form.Item>

              <Form.Item style={{ textAlign: "center" }}>
                <Button htmlType="submit" type="primary" size="large" style={{ padding: "2px 10px", borderRadius: "5px" }} disabled={checkedValue ? false : true}>
                  Verify Email
                </Button>
              </Form.Item>
            </Form>
          )}
        </Card>
      </Content>
    </Layout>
  );
};

export default VerificationWithTerms;
