import React, { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";
import { useRef } from "react";

function EmailDetails({ data = {}, form, onFinish }) {
  const inputRef = useRef();
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleInputChange = () => {
    if (isDisabled) {
      setIsDisabled(false);
    }
  };

  return (
    <Form
      form={form}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      initialValues={{
        email: data?.email,
      }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label="Email"
        name="email"
        hasFeedback
        rules={[
          {
            type: "email",
            message: "Not a valid Email!",
          },
          {
            required: true,
            message: "Please Enter Email!",
          },
          { whitespace: true },
        ]}
      >
        <Input ref={inputRef} placeholder="Enter Email" allowClear autoFocus onChange={handleInputChange} />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 12, span: 12 }}>
        <Button type="primary" htmlType="submit" disabled={isDisabled}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
}

export { EmailDetails };
