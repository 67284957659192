import { Modal, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { SendEmailModal } from "./SendEmailModal";
import { useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { transactionLaneDataHandle } from "../../Rtd/state/actions";
import { useDispatch, useSelector } from "react-redux";
import { commonDataHandle } from "../state/actions";
import { PlusCircleFilled } from "@ant-design/icons";
import { Loading } from "./Loading";

const SignableAndReadOnly = ({ isEmailModalVisible, rootDocData, role, offerId, selectedData, documentId, setIsEmailModalVisible, ispdfClone, source, setisMenuopen, nestedModalOpen, client, userAuthKey, contractType, documentBuyerIds, documentSellerIds, RTDInitiater }) => {
  const initialMountRef = useRef(false);
  const isSmallScreen = useMediaQuery({ maxWidth: 550 });
  const isMediumScreen = useMediaQuery({ minWidth: 551, maxWidth: 850 });
  const [newState, setNewState] = useState({ email: "", isOpen: false });
  const [signerChecked, setSignerChecked] = useState({});
  const [showTextArea, setShowTextArea] = useState(false);
  const { getDoraDocumentsData, getTransactionLaneData, getDoraDocumentsLoading } = useSelector((state) => state.rtdTransactionLane);
  const dispatch = useDispatch();
  const handleEmailCancel = () => {
    setIsEmailModalVisible(false);
    if (source === "SHARE_OMD" || source === "SEND_EMAIL_BUYER_AGENT") {
      setisMenuopen(true);
    } else if (source === "CONTACT_NAV" || source === "SELLING_RTD") {
      setIsEmailModalVisible({ ...isEmailModalVisible, isEmailModalOpen: false, sendMultipleDoc: false });
      setNewState({ ...newState, email: "", fullName: "" });
      setShowTextArea(false);
      initialMountRef.current = false;
      setSignerChecked({});
    }
  };
  useEffect(() => {
    if (userAuthKey && rootDocData?.offerId && source === "CONTACT_NAV") {
      dispatch(transactionLaneDataHandle.getTransactionLaneMethod(rootDocData?.offerId, rootDocData?.RTDInitiator, userAuthKey));
    }
    if (source === "CONTACT_NAV") {
      dispatch(transactionLaneDataHandle.getEmail_Template(userAuthKey));
      dispatch(commonDataHandle.getEmailSignData(userAuthKey));
    }
  }, [userAuthKey]);
  return (
    (source === "CONTACT_NAV" || (source === "SELLING_RTD" && !nestedModalOpen?.isAgent) ? isEmailModalVisible?.isEmailModalOpen : isEmailModalVisible) && (
      <Modal
        visible={source === "CONTACT_NAV" || (source === "SELLING_RTD" && !nestedModalOpen?.isAgent) ? isEmailModalVisible?.isEmailModalOpen : isEmailModalVisible}
        onCancel={handleEmailCancel}
        title={
          <div style={{ textAlign: "center", fontWeight: "bold", color: "#178DFA", fontSize: isSmallScreen ? "14px" : isMediumScreen ? "18px" : "20px" }}>
            {source === "SHARE_OMD" ? "SEND OFFER MANAGEMENT DASHBOARD TO" : source === "SEND_EMAIL_BUYER_AGENT" ? "Send Email To All Buyer Agents" : source === "SHARE_INDIVIDUAL_OFFER" ? "SEND OFFER TO" : source === "CONTACT_NAV" || source === "SELLING_RTD" ? "SEND EMAIL" : "SEND DOCUMENT TO"}
            {!nestedModalOpen?.isAgent && source !== "SHARE_OMD" && source !== "SEND_EMAIL_BUYER_AGENT" && source !== "SHARE_INDIVIDUAL_OFFER" && (
              <Tooltip title="Add Read Only Recepient!" trigger={["hover", "click"]}>
                <PlusCircleFilled size={20} style={{ position: "relative", marginLeft: "10px", marginTop: "5px", fontSize: "25px", color: "#085191", cursor: "pointer" }} onClick={() => setShowTextArea(true)} />
              </Tooltip>
            )}
          </div>
        }
        width={800}
        footer={false}
      >

        <SendEmailModal role={role} initialMountRef={initialMountRef} selectedData={source === "CONTACT_NAV" ? getTransactionLaneData?.[0] : selectedData} documentId={documentId} setIsEmailModalVisible={setIsEmailModalVisible} isEmailModalVisible={isEmailModalVisible} ispdfClone={ispdfClone} source={source === "CONTACT_NAV" ? (rootDocData?.openFor === "SELLER_AGENT" ? "CONTACT_SELLING_RTD" : "CONTACT_BUYING_RTD") : source} handleEmailCancel={handleEmailCancel} isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen} nestedModalOpen={nestedModalOpen} client={client} userAuthKey={userAuthKey} RTDInitiater={source === "CONTACT_NAV" ? rootDocData?.RTDInitiator : RTDInitiater} contractType={contractType} BuyerIds={documentBuyerIds} SellerIds={documentSellerIds} offerId={offerId} rootDocData={rootDocData} newState={newState} setNewState={setNewState} showTextArea={showTextArea} setShowTextArea={setShowTextArea} />
     
      </Modal>
    )
  );
};

export { SignableAndReadOnly };
