import { Button, Input, Space, Table, Typography, Select } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { logsDataHandle } from '../state/actions';
import { Loading } from '../../Common';
import { FilterFilled, SearchOutlined } from "@ant-design/icons"
import "../styles/logs.css";
import moment from 'moment';
import Highlighter from 'react-highlight-words';

function PropertyCreatedLogs() {
  const location = useLocation();
  const dispatch = useDispatch();
  const searchRef = useRef(false);
  const searchInput = useRef();
  const [searchText, setsearchText] = useState("");
  const [searchedColumn, setsearchedColumn] = useState("");
  const [disableBtn, setdisableBtn] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState({
    logType: (location.pathname === '/propertyCreatedLogs') ? 'MANUAL_PROPERTY_CREATED_LOGS' :
      (location.pathname === '/mlsPropertyCreatedLogs') ? 'MLS_PROPERTY_CREATED_LOGS' : ""
  });
  const { getLogsData, getLogsLoading, totalRecords } = useSelector((state) => state.getLogsData);
  useEffect(() => {
    let queryData = {}
    if (location.pathname === '/propertyCreatedLogs') {
      queryData = {
        logType: 'MANUAL_PROPERTY_CREATED_LOGS',
        page: 1
      }
    } else if (location.pathname === '/mlsPropertyCreatedLogs') {
      queryData = {
        logType: 'MLS_PROPERTY_CREATED_LOGS',
        page: 1
      }
    }
    dispatch(logsDataHandle.getAgentCreatedLogsData(queryData));
    setCurrentPage(1);
    setQuery(queryData);
  }, [location.pathname]);


  const handleResetFilter = () => {
    let queryData = {}
    queryData.page = currentPage;
    if (location.pathname === '/propertyCreatedLogs') {
      queryData.logType = 'MANUAL_PROPERTY_CREATED_LOGS';
    } else if (location.pathname === '/mlsPropertyCreatedLogs') {
      queryData.logType = 'MLS_PROPERTY_CREATED_LOGS';
    }
    dispatch(logsDataHandle.getAgentCreatedLogsData(queryData));
    setQuery(queryData);
    setsearchText("");
    searchRef.current = false;
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    // searchRef.current = true;
    setsearchText(selectedKeys[0]);
    let queryData = {
      ...query,
      ...(
        dataIndex === 'createdByData' ? { createdByNameOrEmail: selectedKeys[0] } :
          dataIndex === 'manualPropertyCreatedForSellerAgentData' ? { createdForNameOrEmail: selectedKeys[0] } :
            dataIndex === 'mlsPropertyCreatedData' ? { property: selectedKeys[0] } :
              dataIndex === 'manualPropertyCreatedData' ? { property: selectedKeys[0] } :
                {}
      )
      ,
    };
    if (location.pathname === '/propertyCreatedLogs') {
      queryData.logType = 'MANUAL_PROPERTY_CREATED_LOGS';
    } else if (location.pathname === '/mlsPropertyCreatedLogs') {
      queryData.logType = 'MLS_PROPERTY_CREATED_LOGS';
    }
    dispatch(logsDataHandle.getAgentCreatedLogsData(queryData));
    setQuery(queryData);
    setsearchedColumn(dataIndex);
  };

  const handleSort = (dataIndex) => {
    let queryData = {
      ...query,
      ...(
        dataIndex === 'mlsPropertyCreatedLog' ? { mlsCreatedThrough: searchText } :
          dataIndex === 'platformUsed' ? { platformUsed: searchText } :
            dataIndex === 'createdAt' ? { sortByCreatedAt: searchText } : {}
      )
    };
    if (location.pathname === '/propertyCreatedLogs') {
      queryData.logType = 'MANUAL_PROPERTY_CREATED_LOGS';
    } else if (location.pathname === '/mlsPropertyCreatedLogs') {
      queryData.logType = 'MLS_PROPERTY_CREATED_LOGS';
    }
    dispatch(logsDataHandle.getAgentCreatedLogsData(queryData));
    setQuery(queryData);
  };

  const handleReset = (clearFilters, setSelectedKeys, dataIndex) => {
    let queryData = { ...query };
    if (location.pathname === '/propertyCreatedLogs') {
      queryData.logType = 'MANUAL_PROPERTY_CREATED_LOGS';
    } else if (location.pathname === '/mlsPropertyCreatedLogs') {
      queryData.logType = 'MLS_PROPERTY_CREATED_LOGS';
    }

    if (dataIndex === 'createdByData') {
      delete queryData.createdByNameOrEmail;
    } else if (dataIndex === 'manualPropertyCreatedForSellerAgentData') {
      delete queryData.createdForNameOrEmail;
    } else if (dataIndex === 'mlsPropertyCreatedData') {
      delete queryData.property;
    } else if (dataIndex === 'manualPropertyCreatedData') {
      delete queryData.property;
    } else if (dataIndex === 'mlsPropertyCreatedLog') {
      delete queryData.mlsCreatedThrough;
    } else if (dataIndex === 'platformUsed') {
      delete queryData.platformUsed;
    } else if (dataIndex === 'createdAt') {
      delete queryData.sortByCreatedAt;
    }
    dispatch(logsDataHandle.getAgentCreatedLogsData(queryData));
    setQuery(queryData);
    clearFilters();
    setSelectedKeys("");
    setsearchText("");
    searchRef.current = false;
  };

  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={
            dataIndex === 'createdByData' ? `Search by name or email` :
              dataIndex === 'manualPropertyCreatedForSellerAgentData' ? `Search by name or email` :
                dataIndex === 'mlsPropertyCreatedData' ? `Search Property` :
                  dataIndex === 'manualPropertyCreatedData' ? `Search Property` : ""
          }
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }}
          onPressEnter={() => { handleSearch(selectedKeys, confirm, dataIndex); setdisableBtn(false); }}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => { handleSearch(selectedKeys, confirm, dataIndex); setdisableBtn(false); }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => { handleReset(clearFilters, setSelectedKeys, dataIndex); setdisableBtn(true); }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  let getSort = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <div style={{ marginBottom: "10px" }}>
          <Select
            style={{ width: "100%" }}
            placeholder={
              dataIndex === 'mlsPropertyCreatedLog' ? `Select Property created through` :
                dataIndex === 'createdAt' ? `Select Order` :
                  dataIndex === 'platformUsed' ? `Select Platform used` : ""
            }
            onChange={(role, event) => {
              setsearchText(role)
            }}
            options={
              dataIndex === 'mlsPropertyCreatedLog' ?
                [
                  {
                    value: "RESIDENTIAL_OFFER",
                    label: "RESIDENTIAL OFFER",
                  },
                  {
                    value: "LINK_MLS",
                    label: "LINK MLS",
                  },
                  {
                    value: "MLS_AGENT_ID",
                    label: "MLS AGENT ID",
                  },
                ] :
                dataIndex === 'createdAt' ?
                  [
                    {
                      value: "ASC",
                      label: "ASCENDING",
                    },
                    {
                      value: "DESC",
                      label: "DESCENDING",
                    },
                  ] :
                  dataIndex === 'platformUsed' ?
                    [
                      {
                        value: "WEB",
                        label: "WEB",
                      },
                      {
                        value: "MOBILE",
                        label: "MOBILE",
                      },
                    ] :
                    []
            }
          />
        </div>
        <Space>
          <Button
            type="primary"
            onClick={() => handleSort(dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => { handleReset(clearFilters, setSelectedKeys, dataIndex); setdisableBtn(true); }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />
    ),

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'Property Name',
      dataIndex: location.pathname === '/propertyCreatedLogs' ? 'manualPropertyCreatedData' : 'mlsPropertyCreatedData',
      key: 'property',
      align: 'center',
      ...getColumnSearchProps(location.pathname === '/propertyCreatedLogs' ? 'manualPropertyCreatedData' : 'mlsPropertyCreatedData'),
      render: (text) => (
        <div>
          <div>{text?.fullAddress}</div>
          <div style={{ color: "grey" }}>{text?.email}</div>
        </div>
      ),
    },
    {
      title: 'Created By',
      dataIndex: 'createdByData',
      key: 'createdByData',
      align: 'center',
      width: '50',
      ...getColumnSearchProps('createdByData'),
      render: (text) => (
        <div>
          <div>{text?.fullName}</div>
          <div style={{ color: "grey" }}>{text?.email}</div>
        </div>
      ),
    },
    {
      title: location.pathname === '/propertyCreatedLogs' ? 'Property created for Seller agent' : 'Created Through',
      dataIndex: location.pathname === '/propertyCreatedLogs' ? 'manualPropertyCreatedForSellerAgentData' : 'mlsPropertyCreatedLog',
      key: 'createdThrough',
      align: 'center',
      ...(location.pathname === '/propertyCreatedLogs' ? getColumnSearchProps('manualPropertyCreatedForSellerAgentData') : getSort('mlsPropertyCreatedLog')),
      render: (text) => (
        <div>
          {location.pathname === '/propertyCreatedLogs' ?
            <>
              <div>{text?.fullName}</div>
              <div>{text?.email}</div>
            </>
            :
            <>
              {
                text?.createdThrough === 'RESIDENTIAL_OFFER' ? <div style={{ color: "blue" }}>RESIDENTIAL OFFER</div> :
                  text?.createdThrough === 'LINK_MLS' ? <div style={{ color: "orange" }}>LINK MLS</div> :
                    text?.createdThrough === 'MLS_AGENT_ID' ? <div style={{ color: "red" }}>MLS AGENT ID</div> : ""
              }
            </>
          }
        </div>
      )

    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      ...getSort('createdAt'),
      render: (text) => (
        <div>
          <div>{moment(text).format("MMMM D, YYYY hh:mm A")}</div>
        </div>
      ),
    },
    {
      title: 'Platform Used',
      dataIndex: 'platformUsed',
      key: 'platformUsed',
      align: 'center',
      ...getSort('platformUsed'),
      render: (text) => (
        <div>
          <div>{text}</div>
        </div>
      )
    },
  ]

  return (
    <div id='logs'>{
      getLogsLoading ?
        <div style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center"
        }}>
          <Loading />
        </div> :
        <>
          <div className='headerDiv'>
            <div className='resetBtn'>
              <Button onClick={handleResetFilter} disabled={Object.keys(query).filter(key => key !== 'logType' && key !== 'page' && key !== 'limit').length === 0}>Reset Filter</Button>
            </div>
            <div className='titleDiv'>
              <h1 style={{ whiteSpace: "nowrap" }}>{`${location.pathname === '/propertyCreatedLogs' ? "MANUAL" : "MLS"} PROPERTY CREATED LOGS`}</h1>
            </div>
            <div className='blankDiv'></div>
          </div>
          <Table
            dataSource={getLogsData}
            columns={columns}
            loading={getLogsLoading}
            bordered
            pagination={{
              position: ["bottomCenter"],
              showSizeChanger: false,
              pageSize: 5,
              current: currentPage,
              total: totalRecords,
              onChange: (page) => {
                if (!searchRef.current) {
                  searchRef.current = true;
                  return
                }
                dispatch(logsDataHandle.getAgentCreatedLogsData({
                  ...query,
                  logType: location.pathname === '/propertyCreatedLogs' ? 'MANUAL_PROPERTY_CREATED_LOGS':'MLS_PROPERTY_CREATED_LOGS',
                  page: page
                }));
                setQuery({
                  ...query,
                  logType: location.pathname === '/propertyCreatedLogs' ? 'MANUAL_PROPERTY_CREATED_LOGS':'MLS_PROPERTY_CREATED_LOGS',
                  page: page
                })
                setCurrentPage(page);
              },
            }}
            scroll={{
              y: 480,
              x: 1000,
            }}
          />
        </>}
    </div>
  )
}

export default PropertyCreatedLogs
