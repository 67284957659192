import { Button, Input, Select, Space, Table } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { logsDataHandle } from '../state/actions';
import moment from 'moment';
import { Loading } from '../../Common';
import { SearchOutlined, FilterFilled, } from "@ant-design/icons"
import Highlighter from 'react-highlight-words';
import "../styles/logs.css";

function BrokerCreatedLogs() {
  const location = useLocation();
  const dispatch = useDispatch();
  const searchRef = useRef(false);
  const searchInput = useRef();
  const [searchText, setsearchText] = useState("");
  const [searchedColumn, setsearchedColumn] = useState("");
  const [disableBtn, setdisableBtn] = useState(true);
  const [query, setQuery] = useState({
    logType: (location.pathname === '/buyerAgentCreatedLogs') ? 'BUYER_AGENT_CREATED_LOGS' :
      (location.pathname === '/sellerAgentCreatedLogs') ? 'SELLER_AGENT_CREATED_LOGS' : ""
  });
  const { getLogsData, getLogsLoading, totalRecords } = useSelector((state) => state.getLogsData);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    let queryData = {};
    if (location.pathname === '/buyerAgentCreatedLogs') {
      queryData = {
        logType: 'BUYER_AGENT_CREATED_LOGS',
        page: 1
      }
    } else if (location.pathname === '/sellerAgentCreatedLogs') {
      queryData = {
        logType: 'SELLER_AGENT_CREATED_LOGS',
        page: 1
      }
    }
    dispatch(logsDataHandle.getAgentCreatedLogsData(queryData));
    setCurrentPage(1);
    setQuery(queryData);
  }, [location.pathname])

  const handleResetFilter = () => {
    let queryData = {};
    queryData.page = currentPage;
    if (location.pathname === '/buyerAgentCreatedLogs') {
      queryData.logType = 'BUYER_AGENT_CREATED_LOGS';
    } else if (location.pathname === '/sellerAgentCreatedLogs') {
      queryData.logType = 'SELLER_AGENT_CREATED_LOGS';
    }
    dispatch(logsDataHandle.getAgentCreatedLogsData(queryData));
    setQuery(queryData);
    setsearchText("");
    searchRef.current = false;
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    searchRef.current = true;
    setsearchText(selectedKeys[0]);
    let queryData = {
      ...query,
      ...((dataIndex === 'sellerAgentCreatedOnPropertyData' || dataIndex === 'buyerAgentCreatedOnOfferIdToPropertyData') ? { property: selectedKeys[0] } : { [dataIndex]: selectedKeys[0] }),
    };
    if (location.pathname === '/buyerAgentCreatedLogs') {
      queryData.logType = 'BUYER_AGENT_CREATED_LOGS';
    } else if (location.pathname === '/sellerAgentCreatedLogs') {
      queryData.logType = 'SELLER_AGENT_CREATED_LOGS';
    }
    dispatch(logsDataHandle.getAgentCreatedLogsData(queryData));
    setQuery(queryData);
    setsearchedColumn(dataIndex);
  };

  const handleSort = (dataIndex) => {
    let queryData = {
      ...query,
      ...(
        dataIndex === 'createdAt' ? { sortByCreatedAt: searchText } :
          dataIndex === 'platformUsed' ? { platformUsed: searchText } : {}
      ),
    };
    if (location.pathname === '/buyerAgentCreatedLogs') {
      queryData.logType = 'BUYER_AGENT_CREATED_LOGS';
    } else if (location.pathname === '/sellerAgentCreatedLogs') {
      queryData.logType = 'SELLER_AGENT_CREATED_LOGS';
    }
    dispatch(logsDataHandle.getAgentCreatedLogsData(queryData));
    setQuery(queryData);
  };

  const handleReset = (clearFilters, setSelectedKeys, dataIndex) => {
    let queryData = { ...query };
    if (location.pathname === '/buyerAgentCreatedLogs') {
      queryData.logType = 'BUYER_AGENT_CREATED_LOGS';
    } else if (location.pathname === '/sellerAgentCreatedLogs') {
      queryData.logType = 'SELLER_AGENT_CREATED_LOGS';
    }

    // Remove the parameters dependent on the column being reset
    if (dataIndex === 'buyerAgentNameOrEmail') {
      delete queryData.buyerAgentNameOrEmail;
    } else if (dataIndex === 'sellerAgentNameOrEmail') {
      delete queryData.sellerAgentNameOrEmail;
    } else if (dataIndex === 'createdByNameOrEmail') {
      delete queryData.createdByNameOrEmail;
    } else if (dataIndex === 'sellerAgentCreatedOnPropertyData') {
      delete queryData.property;
    } else if (dataIndex === 'buyerAgentCreatedOnOfferIdToPropertyData') {
      delete queryData.property;
    } else if (dataIndex === 'createdAt') {
      delete queryData.sortByCreatedAt;
    } else if (dataIndex === 'platformUsed') {
      delete queryData.platformUsed;
    }

    dispatch(logsDataHandle.getAgentCreatedLogsData(queryData));
    clearFilters();
    setSelectedKeys("");
    setQuery(queryData);
    setsearchText("");
    searchRef.current = false;
  };

  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }}
          onPressEnter={() => { handleSearch(selectedKeys, confirm, dataIndex); setdisableBtn(false); }}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => { handleSearch(selectedKeys, confirm, dataIndex); setdisableBtn(false); }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => { handleReset(clearFilters, setSelectedKeys, dataIndex); setdisableBtn(true); }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  let getSort = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <div style={{ marginBottom: "10px" }}>
          <Select
            style={{ width: "100%" }}
            placeholder="Select Order"
            onChange={(role, event) => {
              setsearchText(role)
            }}
            options={
              dataIndex === 'createdAt' ?
                [
                  {
                    value: "ASC",
                    label: "ASCENDING",
                  },
                  {
                    value: "DESC",
                    label: "DESCENDING",
                  },
                ] :
                dataIndex === 'platformUsed' ?
                  [
                    {
                      value: "WEB",
                      label: "WEB",
                    },
                    {
                      value: "MOBILE",
                      label: "MOBILE",
                    },
                  ] :
                  []
            }
          />
        </div>
        <Space>
          <Button
            type="primary"
            onClick={() => handleSort(dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => { handleReset(clearFilters, setSelectedKeys, dataIndex); setdisableBtn(true); }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />
    ),

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: `${location.pathname === '/buyerAgentCreatedLogs' ? "Buyer" : "Seller"} Agent`,
      dataIndex: `${location.pathname === '/buyerAgentCreatedLogs' ? 'buyerAgentData' : 'sellerAgentData'}`,
      key: 'AgentName',
      align: 'center',
      width: '50',
      fixed: 'left',
      ...getColumnSearchProps(location.pathname === '/buyerAgentCreatedLogs' ? 'buyerAgentNameOrEmail' : 'sellerAgentNameOrEmail'),
      render: (text) => (
        <div>
          <div>{text?.fullName}</div>
          <div style={{ color: "grey" }}>{text?.email}</div>
        </div>
      ),
    },
    {
      title: 'Created By',
      dataIndex: 'createdByData',
      key: 'createdByData',
      align: 'center',
      width: '50',
      ...getColumnSearchProps('createdByNameOrEmail'),
      render: (text) => (
        <div>
          <div>{text?.fullName}</div>
          <div style={{ color: "grey" }}>{text?.email}</div>
        </div>
      ),
    },
    {
      title: `${location.pathname === '/sellerAgentCreatedLogs' ? "Seller" : "Buyer"} Agent Created on Property`,
      dataIndex: location.pathname === '/sellerAgentCreatedLogs' ? 'sellerAgentCreatedOnPropertyData' : 'buyerAgentCreatedOnOfferIdToPropertyData',
      key: 'property',
      align: 'center',
      ...getColumnSearchProps(location.pathname === '/sellerAgentCreatedLogs' ? 'sellerAgentCreatedOnPropertyData' : 'buyerAgentCreatedOnOfferIdToPropertyData'),
      render: (text) => (
        <div>
          <div>{text?.fullAddress}</div>
        </div>
      )
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      ...getSort('createdAt'),
      render: (text) => (
        <div>
          <div>{moment(text).format("MMMM D, YYYY hh:mm A")}</div>
        </div>
      ),
    },
    {
      title: 'Platform Used',
      dataIndex: 'platformUsed',
      key: 'platformUsed',
      align: 'center',
      ...getSort('platformUsed'),
      render: (text) => (
        <div>
          <div>{text}</div>
        </div>
      )
    },
  ]

  return (
    <div id='logs'>{
      getLogsLoading ?
        <div style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center"
        }}>
          <Loading />
        </div> :
        <>
          <div className='headerDiv'>
            <div className='resetBtn'>
              <Button onClick={handleResetFilter} disabled={Object.keys(query).filter(key => key !== 'logType' && key !== 'page' && key !== 'limit').length === 0}>Reset Filter</Button>
            </div>
            <div className='titleDiv'>
              <h1>{`${location.pathname === '/buyerAgentCreatedLogs' ? "BUYER" : "SELLER"} AGENT CREATED LOGS`}</h1>
            </div>
            <div className='blankDiv'></div>
          </div>
          <Table
            dataSource={getLogsData}
            columns={columns}
            loading={getLogsLoading}
            bordered
            pagination={{
              position: ["bottomCenter"],
              showSizeChanger: false,
              pageSize: 5,
              current: currentPage,
              total: totalRecords,
              onChange: (page) => {
                if (!searchRef.current) {
                  searchRef.current = true;
                  return
                }
                dispatch(logsDataHandle.getAgentCreatedLogsData({
                  ...query,
                  logType: location.pathname === '/buyerAgentCreatedLogs' ? 'BUYER_AGENT_CREATED_LOGS':'SELLER_AGENT_CREATED_LOGS',
                  page: page
                }));
                setQuery({
                  ...query,
                  logType: location.pathname === '/buyerAgentCreatedLogs' ? 'BUYER_AGENT_CREATED_LOGS':'SELLER_AGENT_CREATED_LOGS',
                  page: page
                })
                setCurrentPage(page);
              },
            }}
            scroll={{
              y: 460,
              x: 1000,
            }}
          />
        </>}
    </div>
  )
}

export default BrokerCreatedLogs
