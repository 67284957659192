import { Modal, Tooltip } from "antd";
import React, { useState } from "react";
import { ImportOutlined, FilePdfOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { OfferCloneModal } from "./OfferCloneModal";
import { OfferImportModal } from "./OfferImportModal";
export const encryptStr = (str) => {
  let n = str?.length;
  let newStr = "";
  for (let i = 0; i < n; i++) {
    if (i !== 0 && i % 4 === 0) {
      newStr += str[i] + "-";
    } else {
      newStr += str[i];
    }
  }
  return newStr;
};
const OfferImport = ({ isModalOpen, setIsModalOpen }) => {
  const [offerImportModalVisible, setOfferImportModalVisible] = useState(false);
  const [urlImportModalVisible, setUrlImportModalVisible] = useState(false);
  const { offerTablePropertyId, URLofferTableJSON, isAccpetedOfferPresent } = useSelector((state) => state.offerTable);
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <OfferCloneModal urlImportModalVisible={urlImportModalVisible} setUrlImportModalVisible={setUrlImportModalVisible} selectedData={URLofferTableJSON}  />
      <OfferImportModal offerImportModalVisible={offerImportModalVisible} setOfferImportModalVisible={setOfferImportModalVisible} />

      <Modal title={<div style={{ display: "flex", justifyContent: "center", color: "grey", fontWeight: "bold", fontSize: "20px" }}>OPTIONS FOR OFFER IMPORT</div>} footer={false} visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div className="modal_row" style={{ width: "97%", display: "flex", justifyContent: "flexStart", fontSize: "20px", marginTop: "10px" }}>
          <div className="icon_div">
            <ImportOutlined style={{ fontSize: "22px", color: "black" }} />
          </div>
          <div
            style={{
              pointerEvents: offerTablePropertyId ? "auto" : "none",
              display: "flex",
            }}
            className="content_div"
            onClick={() => {
              setUrlImportModalVisible(true);
            }}
          >
            <Tooltip placement="top" title={"This feature will be available soon."}>
              <p className="modalContent" style={{ marginLeft: "10px", fontSize: "22px", fontWeight: "bolder", color: "grey", cursor: "context-menu" }}>
                Offer Clone (URL)
              </p>
            </Tooltip>
          </div>
        </div>
        <div className="modal_row" style={{ width: "97%", display: "flex", justifyContent: "flexStart", fontSize: "20px", marginTop: "10px" }}>
          <div className="icon_div">
            <FilePdfOutlined style={{ fontSize: "22px", color: "black" }} />
          </div>
          <div className="content_div">
            <Tooltip placement="top" title={isAccpetedOfferPresent ? "Accepted Offer Exists: Cannot Import PDF" : "Import offer from PDF document "}>
              <button
                onClick={() => {
                  setOfferImportModalVisible(true);
                }}
                style={{
                  background: "white",
                  border: "none",
                  fontSize: "22px",
                  fontWeight: "bolder",
                  marginTop: "-5px",
                  marginLeft: "10px",
                  color: isAccpetedOfferPresent ? "grey" : "hsl(209, 85%, 39%)",
                  pointerEvents: isAccpetedOfferPresent ? "none" : "auto",
                  cursor: "pointer",
                }}
                className={"modalContentButton"}
                disabled={isAccpetedOfferPresent ? true : false}
              >
                Import Offer (PDF)
              </button>
            </Tooltip>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default OfferImport;
